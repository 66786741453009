/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

import Tooltip from '../Tooltip';
import { setGenitiv } from '../../service/frageUtils';

export default function BeschreibungMitTooltip({
  beschreibung,
  tooltipText,
  tooltipID,
  gebaeudeArt,
}) {
  return (
    <div
      key={tooltipID}
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      {beschreibung ? (
        <p
          css={css`
            margin-right: 10px;
          `}
          dangerouslySetInnerHTML={{
            __html: setGenitiv(beschreibung, gebaeudeArt),
          }}
        />
      ) : tooltipText ? (
        'Hinweis'
      ) : null}
      <Tooltip
        tooltipText={setGenitiv(tooltipText, gebaeudeArt)}
        tooltipID={'InteraktivesElementTooltip'}
      />
    </div>
  );
}
