/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React from 'react'; // eslint-disable-line

import colors from '../../styles/colors.js';
import { breakpoints } from '../../styles/breakpoints';

const dektopLeftRightSpace = '25%';
const smallDektopLeftRightSpace = '20%';
const tabletLeftRightSpace = '4%';
const mobilepLeftRightSpace = '10px';

const UpperSide = (props) => (
  <div
    css={css`
      background-color: ${colors.blue};
      color: white;
      display: grid;
      /* [blauer space links] [content] [blauer space rechts]*/
      grid-template-columns: ${dektopLeftRightSpace} auto ${dektopLeftRightSpace};
      /* [blauer space oben] [content] [blauer space unten]*/
      grid-template-rows: 40px auto 10px;

      @media (max-width: 1100px) {
        grid-template-columns: ${smallDektopLeftRightSpace} auto ${smallDektopLeftRightSpace};
        grid-template-rows: 40px auto 5px;
      }

      @media (${breakpoints.tablet}) {
        grid-template-columns: ${tabletLeftRightSpace} auto ${tabletLeftRightSpace};
        grid-template-rows: 40px auto 10px;
      }

      @media (${breakpoints.smartphone}) {
        grid-template-columns: ${mobilepLeftRightSpace} auto ${mobilepLeftRightSpace};
        grid-template-rows: 35px auto 10px;
      }
    `}
    id="fragen-start"
  >
    <div
      css={css`
        grid-row: 1/2;
      `}
    ></div>
    <div
      css={css`
        grid-column: 2/3;
        grid-row: 2/3;
      `}
    >
      {props.children}
    </div>
    <div
      css={css`
        grid-row: 3/3;
      `}
    ></div>
  </div>
);

export default UpperSide;
