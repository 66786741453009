/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

import React, { useState, useEffect } from 'react';

import LowerSide from '../components/Layout/LowerSide';
import TwoRowsBox from '../components/Layout/TwoRowsBox';
import { fetchSeitenDaten } from '../service/fetchConfig';
import { ENDPOINTS } from '../service/api';

export default function Impressum() {
  //Get Parameter
  const [seitenDaten, setSeitenDaten] = useState();
  useEffect(() => {
    const ladeSeitenDaten = async () => {
      const seitenDaten = (await fetchSeitenDaten(ENDPOINTS.IMPRESSUM)).data;
      setSeitenDaten(seitenDaten);
    };

    ladeSeitenDaten();
  }, []);

  return (
    <TwoRowsBox>
      <LowerSide>
        <h1>Impressum</h1>
        {seitenDaten && (
          <div dangerouslySetInnerHTML={{ __html: seitenDaten.text1 }} />
        )}
      </LowerSide>
    </TwoRowsBox>
  );
}
