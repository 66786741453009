/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';

const AuswahlReset = ({ zurueckSetzen }) => (
  <div
    css={css`
      text-align: right;
    `}
  >
    <a
      onClick={() => zurueckSetzen()}
      onKeyUp={(key) => {
        if (key.code === 'Enter') {
          zurueckSetzen();
        }
      }}
      tabIndex="0"
      css={css`
        text-align: right;
        font-size: 12px;
        font-family: 'BentonSans-Regular', sans-serif;
        color: #595959;

        &:hover {
          cursor: pointer;
        }
      `}
    >
      Auswahl zurücksetzen
    </a>
  </div>
);

export default AuswahlReset;
