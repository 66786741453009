const colors = {
  blue: '#003064',
  gray: '#575757',
  darkgray: '#707070',
  lightgray: '#ACACAC',
  lightlightgray: '#f0f0f0',
  placeholder: '#575757',
};

export default colors;
