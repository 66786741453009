/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

const Fortschrittsbalken = ({ frageID, alleFragen }) => {
  // Helper functions
  const erhalteIndexViaFrageID = () => {
    //Bsp. 03-02-04 => ["03", "02", "04"] => "04" => 4
    if (/-02-/g.test(frageID)) {
      let result = frageID.split('-'); // teile frage in 3 elemente array auf
      result = result[2]; // nehme nur den index aus dem array
      return parseInt(result); // rückgabe als int
    } else {
      return (
        erhalteAnzahlFragenImFragebogen().findIndex(
          (frage) => frage.id === frageID
        ) + 1
      ); //+1 wegen initialer Startfrage;
    }
  };

  const erhalteFragebogenID = () => frageID.split('-')[0];

  // Diverse Filter Funktionen
  const gebeFragenAusFragebogen01Zurueck = () =>
    alleFragen.filter(
      //alle Fragen aus dem zweiten Block werden ignoriert, da dise separat behandelt werden
      (frage) => /^01/g.test(frage.id) && !/-02-/g.test(frage.id)
    );
  const gebeFragenAusFragebogen02Zurueck = () =>
    alleFragen.filter(
      //alle Fragen aus dem zweiten Block werden ignoriert, da dise separat behandelt werden
      (frage) => /^02/g.test(frage.id) && !/-02-/g.test(frage.id)
    );
  const gebeFragenAusFragebogen03Zurueck = () =>
    //alle Fragen aus dem zweiten Block werden ignoriert, da dise separat behandelt werden
    alleFragen.filter(
      (frage) => /^03/g.test(frage.id) && !/-02-/g.test(frage.id)
    );
  const erhalteNurFragenAusDem02Block = () => {
    const fragebogenNummer = erhalteFragebogenID();
    if (fragebogenNummer === '01')
      return alleFragen.filter((frage) => /^01-02-/g.test(frage.id));
    else if (fragebogenNummer === '02')
      return alleFragen.filter((frage) => /^02-02-/g.test(frage.id));
    else if (fragebogenNummer === '03')
      return alleFragen.filter((frage) => /^03-02-/g.test(frage.id));
  };

  const erhalteAnzahlFragenImFragebogen = () => {
    if (/-02-/g.test(frageID)) return erhalteNurFragenAusDem02Block();

    const fragebogenNummer = erhalteFragebogenID();
    if (fragebogenNummer === '01') return gebeFragenAusFragebogen01Zurueck();
    else if (fragebogenNummer === '02')
      return gebeFragenAusFragebogen02Zurueck();
    else if (fragebogenNummer === '03')
      return gebeFragenAusFragebogen03Zurueck();
    //fallback wenn Frage nicht zugeordnet werden kann.
    else return [];
  };

  const berechneFortschritt = () => {
    // Bsp: 3 / 7 => 0.4285 => 42.85 => 43 => "43%"
    let result = aktuellerIndex / erhalteAnzahlFragenImFragebogen().length; // erhalte math. prozentualen Wert
    result *= 100; // auf anzeigbare Prozente umrechnen
    result.toFixed(0); // aufrunden auf ganze Prozent
    return `${result}%`; // Rückgabe als Prozentzahl
  };

  // "States"
  const aktuellerIndex = erhalteIndexViaFrageID(frageID);

  // Render
  if (['01-01-01', '02-01-01', '03-01-01'].includes(frageID)) {
    return null;
  } // verstecke Fortschrittsbalken bei der Startfrage
  if (['ZUSAMMENFASSUNG', 'FEEDBACK', 'DANKE', 'FEHLER'].includes(frageID)) {
    return null;
  } // verstecke Fortschrittsbalken beim Feedback

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 23px;
        background-color: #ffffff;
        box-sizing: border-box;
        border: 1px solid #003064;
      `}
    >
      <p
        css={css`
          font-family: 'BentonSans-Bold', sans-serif;
          font-size: 14px;
          margin-left: 38px;
          z-index: 2;
        `}
      >
        Fortschritt {aktuellerIndex} von{' '}
        {erhalteAnzahlFragenImFragebogen().length}
      </p>
      <div
        css={css`
          position: absolute;
          width: ${berechneFortschritt()};
          height: 21px; // heigth vom parent -border_width oben und unten
          background-color: #b3cae3;
          z-index: 1;
        `}
      />
    </div>
  );
};

export default Fortschrittsbalken;
