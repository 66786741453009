import axios from 'axios';
import { API_HOST, ENDPOINTS, defaultAuth } from './api';

export function sendFeedback(
  ausschuss,
  gemeinde,
  pruefCode,
  regNummer,
  feedback
) {
  const url = `${API_HOST}${ENDPOINTS.FEEDBACK}/${ausschuss}/${gemeinde}/${regNummer}/${pruefCode}`;
  return axios({
    url,
    method: 'post',
    data: feedback,
  });
}
