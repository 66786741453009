/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/react';
import { v4 as generateUuid } from 'uuid';

import { UploadStatus } from './UploadStatus';
import { breakpoints } from '../../styles/breakpoints';
import styles from './Answer.module.css';
import {
  uploadDateiFuerFrage,
  loescheDateiFuerFrage,
} from '../../service/dateiUpload';

const hasValue = (element) =>
  !!(element && element.current && element.current.value);

const getDateiendung = (datei) => /.*\.([a-zA-Z]+)$/.exec(datei.name)[1];

export const EinzelUpload = ({
  frageMetadaten,
  frageId,
  hochgeladeneDatei,
  id,
  isLastElement,
  onAdd,
  onDelete,
  text,
  erlaubeAlleFormate,
}) => {
  const [file, setFile] = React.useState(null);
  const [isDragOver, setIsDragOver] = React.useState(false);
  const [status, setStatus] = React.useState({});
  const inputElement = React.useRef(null);

  const uploadAnzeigen =
    status.upload !== 'success' && status.upload !== 'bereitsHochgeladen';

  const entfernenAnzeigen =
    status.upload === 'success' ||
    status.upload === 'bereitsHochgeladen' ||
    status.delete === 'error' ||
    status.delete === 'pending' ||
    hasValue(inputElement);

  const akzeptierteDateitypen = erlaubeAlleFormate
    ? ''
    : 'image/png,image/jpeg,.pdf';

  React.useEffect(() => {
    if (hochgeladeneDatei) {
      setStatus({ upload: 'bereitsHochgeladen' });
    }
  }, [hochgeladeneDatei]);

  const selectFile = (event) => {
    setIsDragOver(false);
    if (event.target.files.length === 0) {
      return;
    }
    const file = event.target.files[0];

    setFile(file);
    const dateiEndung = getDateiendung(file);
    const neueUUID = generateUuid();

    setStatus({ upload: 'pending' });
    uploadDateiFuerFrage(
      file,
      id,
      frageMetadaten,
      dateiEndung,
      erlaubeAlleFormate,
      neueUUID
    )
      .then(() => {
        setStatus({ upload: 'success' });
        const dateiname = `${frageMetadaten.frageId}_${id}_${neueUUID}.${dateiEndung}`;
        onAdd(id, dateiname, file.name, file.type, neueUUID);
      })
      .catch((error) => {
        if (error?.response?.status === 415) {
          setStatus({upload: 'invalidesFormat'});
        } else if (error?.response?.status === 403) {
          setStatus({upload: 'virenScanPositiv'});
        } else if (error?.response?.status === 500 ||  error?.response?.status === 503) {
          setStatus({upload: 'gatewayNichtErreichbar'})
        } else {
          setStatus({ upload: 'error' });
        }
        inputElement.current.value = null;
        setFile(null);
        console.error(error);
      });
  };

  const removeFile = () => {
    setStatus({ delete: 'pending' });
    loescheDateiFuerFrage(id, frageMetadaten, hochgeladeneDatei)
      .then(() => {
        inputElement.current.value = null;
        setFile(null);
        setStatus({ delete: 'success' });
        onDelete(id, hochgeladeneDatei.uuid);
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          // if the file was not found, it was already deleted so we are fine
          setStatus({ delete: 'success' });
          onDelete(id, hochgeladeneDatei.uuid);
        } else {
          setStatus({ delete: 'error' });
          console.error(error);
        }
      });
  };

  return (
    <div
      css={css`
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: ${isLastElement ? '' : '1px solid #003064'};
        display: flex;
        justify-content: space-between;
      `}
    >
      <div>
        <div
          css={css`
            font-weight: bold;
          `}
        >
          {text}:
        </div>
        <div>
          <span>
            <em>
              {file
                ? file.name
                : status.upload === 'bereitsHochgeladen'
                ? ''
                : 'noch nichts ausgewählt'}
            </em>
          </span>
          <UploadStatus status={status} dateiname={hochgeladeneDatei?.originalDateiname} />
        </div>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-content: center;
          justify-content: center;
        `}
      >
        <label
          tabIndex="0"
          aria-label={'Datei hinzufügen für ' + text}
          htmlFor={'input-file-' + frageId}
          css={css`
            display: ${uploadAnzeigen ? 'block' : 'none'};
            position: relative;
            border: ${isDragOver ? '3px dashed green' : '1px dashed #003064'};
            border-radius: 10px;
            padding: 5px;
            margin-bottom: 10px;
            text-align: center;
            width: 200px;

            @media (${breakpoints.smartphone}) {
              width: 100%;
              border: 0;
            }
          `}
          onDragOver={() => setIsDragOver(true)}
          onDragEnd={() => setIsDragOver(false)}
          onDragExit={() => setIsDragOver(false)}
          onKeyUp={(key) => {
            if (key.code === 'Enter') {
              inputElement.current.click();
            }
          }}
        >
          <div
            className={'fake-upload-button ' + styles.buttonStyle}
            aria-hidden={true}
          >
            Datei hinzufügen
          </div>
          <div
            css={css`
              display: inline-block;
              @media (${breakpoints.smartphone}) {
                display: none;
              }
            `}
          >
            <div className="oder">Oder</div>
            <div
              className="additional-description"
              css={css`
                font-weight: bold;
                margin: auto 10px;
              `}
              aria-hidden={true}
            >
              Datei hierher ziehen
            </div>
          </div>
          <input
            type="file"
            tabIndex="-1"
            id={'input-file-' + frageId}
            accept={akzeptierteDateitypen}
            required={true}
            onChange={selectFile}
            ref={inputElement}
            css={css`
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              width: 100%;
              -webkit-tap-highlight-color: transparent;
              opacity: 0;
              z-index: 1;
            `}
          />
        </label>
        <div
          css={css`
            margin: 0 auto;
            display: ${entfernenAnzeigen ? 'block' : 'none'};
          `}
        >
          <button className={styles.buttonStyle} onClick={removeFile}>
            Datei entfernen
          </button>
        </div>
      </div>
    </div>
  );
};
