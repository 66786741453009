/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

import React, { useState, useEffect } from 'react'; // eslint-disable-line
import { useLocation } from 'react-router-dom';

import LowerSide from '../components/Layout/LowerSide';
import TwoRowsBox from '../components/Layout/TwoRowsBox';
import {
  fetchAusschuesseDetails,
  fetchSeitenDaten,
} from '../service/fetchConfig';
import { ENDPOINTS } from '../service/api';
import EmailLink from '../components/EmailLink';

const noMarginTop = css`
  margin-top: 0px;
`;
const noMarginBottom = css`
  margin-bottom: 0px;
`;
export default function Datenschutz() {
  const [alleAusschuesse, setAlleAusschuesse] = useState([]);
  useEffect(() => {
    const ladeAusschuss = async () => {
      const alleAusschuesseData = (await fetchAusschuesseDetails()).data;
      alleAusschuesseData.sort((a, b) => {
        //Sortiere den Oberen GAA nach ganz oben
        if (a.kennziffer === '0') {
          return -1;
        } else if (b.kennziffer === '0') {
          return 1;
        } else {
          if (a.vollerName < b.vollerName) {
            return -1;
          }
          if (a.vollerName > b.vollerName) {
            return 1;
          }
          return 0;
        }
      });
      setAlleAusschuesse(alleAusschuesseData);
    };

    ladeAusschuss();
  }, []);

  const [seitenDaten, setSeitenDaten] = useState();
  useEffect(() => {
    const ladeSeitenDaten = async () => {
      const seitenDaten = (await fetchSeitenDaten(ENDPOINTS.DATENSCHUTZ)).data;
      setSeitenDaten(seitenDaten);
    };

    ladeSeitenDaten();
  }, []);

  return (
    <TwoRowsBox>
      <LowerSide>
        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: repeat(auto-fit, auto);
            grid-gap: 0px 38px;
          `}
        >
          <h1>Datenschutz</h1>
          {seitenDaten && (
            <div dangerouslySetInnerHTML={{ __html: seitenDaten.text1 }} />
          )}
          <h2> Auflistung der Verantwortlichen für die Datenverarbeitung</h2>
          <table
            css={css`
              border-spacing: 0px 30px;
            `}
          >
            <tbody>
              <tr>
                <th
                  css={css`
                    text-align: left;
                  `}
                >
                  Verantwortlich für die Datenverarbeitung
                </th>
                <th
                  css={css`
                    text-align: left;
                  `}
                >
                  Kontaktdaten der Datenschutzbeauftragten
                </th>
              </tr>

              {alleAusschuesse.map((ausschuss) => (
                <tr key={'Ausschusszeile' + ausschuss.vollerName}>
                  <td
                    css={css`
                      vertical-align: top;
                    `}
                  >
                    <strong> {ausschuss && ausschuss.vollerName} </strong>
                    <p css={[noMarginBottom]}>
                      {ausschuss && ausschuss.gaaStrasseHausnr}
                    </p>
                    <p css={[noMarginTop, noMarginBottom]}>
                      {ausschuss && ausschuss.gaaPlz}
                      {' ' /*Dieses Leerzeichen trennt PLZ und Ort*/}
                      {ausschuss && ausschuss.gaaOrt}
                    </p>
                    <p css={[noMarginTop, noMarginBottom]}>
                      Tel.:{' '}
                      <EmailLink
                        email={ausschuss && ausschuss.gaaTelefon}
                        protocol="tel"
                      />
                    </p>
                    <p css={[noMarginTop]}>
                      E-Mail:{' '}
                      <EmailLink email={ausschuss && ausschuss.gaaEmail} />
                    </p>
                  </td>
                  <td
                    css={css`
                      vertical-align: top;
                    `}
                  >
                    <strong>- persönlich - </strong>
                    <p css={[noMarginBottom]}>
                      {' '}
                      {ausschuss && ausschuss.datenschutzbeauftragter}
                    </p>
                    <p css={[noMarginTop, noMarginBottom]}>
                      {' '}
                      {ausschuss && ausschuss.dsbStrasseHausnr}
                    </p>
                    <p css={[noMarginTop, noMarginBottom]}>
                      {ausschuss && ausschuss.dsbPlz}
                      {' ' /*Dieses Leerzeichen trennt PLZ und Ort*/}
                      {ausschuss && ausschuss.dsbOrt}
                    </p>
                    <p css={[noMarginTop, noMarginBottom]}>
                      Tel.:{' '}
                      <EmailLink
                        email={ausschuss && ausschuss.dsbTelefon}
                        protocol="tel"
                      />
                    </p>
                    <p css={[noMarginTop]}>
                      E-Mail:{' '}
                      <EmailLink email={ausschuss && ausschuss.dsbEmail} />
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {seitenDaten && (
            <div dangerouslySetInnerHTML={{ __html: seitenDaten.text2 }} />
          )}
        </div>
      </LowerSide>
    </TwoRowsBox>
  );
}
