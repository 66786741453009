/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

import React, { useState, useEffect } from 'react'; // eslint-disable-line

import { useParams, useLocation, useHistory, Redirect } from 'react-router-dom';

import { HashLink } from 'react-router-hash-link';

import { breakpoints } from '../styles/breakpoints';
import colors from '../styles/colors.js';

import { sendErgebnisse, fetchErgebnisse } from '../service/ergebnisseAPI';
import { sendFeedback } from '../service/feedbackAPI';

import LowerSide from '../components/Layout/LowerSide';
import UpperSide from '../components/Layout/UpperSide';
import TwoRowsBox from '../components/Layout/TwoRowsBox';

import Fortschrittsbalken from '../components/Fortschrittsbalken';

import Button from '../components/Button';
import LoadingAnimation from '../components/LoadingAnimation';
import FrageKopf from '../components/FrageKopf';
import FrageSwitch from '../components/FrageSwitch';

import {
  fetchFragen12,
  fetchFragen3,
  fetchAusschuesse,
  fetchGemeinden,
} from '../service/fetchConfig';

const isRegelnActive = process.env.REACT_APP_IS_REGELN_ACTIVE === 'true';

export default function Frage() {
  const { id } = useParams();

  const history = useHistory();
  const query = useLocation().search;
  const params = new URLSearchParams(query);

  const ausschuss = params.get('ausschuss');
  const gemeinde = params.get('gemeinde');
  const pruefCode = params.get('code');
  const regNummer = params.get('regnummer');

  const [ausschussName, setAusschussName] = useState(ausschuss);
  const [vollerAusschussName, setVollerAusschussName] = useState(ausschuss);
  const [gemeindeName, setGemeindeName] = useState(gemeinde);

  // == States ==
  const [nichtGefunden, setNichtGefunden] = useState(false);
  const [frage, setFrage] = useState(null);
  const [frageId, setFrageId] = useState(id);
  const [naechsteFrageId, setNaechsteFrageId] = useState(id);

  const [fragen, setFragen] = useState(null); //Liste aller Fragen
  const [gebaeudeArt, setGebaeudeArt] = useState(null);

  //Letzte-Fragen-Stack zum zurück Navigieren
  const [letzteFragenIds, setLetzteFragenIds] = useState([]);

  //aktuelle Wert der Antwort
  const [aktuelleAntwort, setAktuelleAntwort] = useState(null);

  //Ergebnis wird bei jedem ändern der Antwort sofort übertragen wenn true
  const [ergebnisSofortSenden, setErgebnisSofortSenden] = useState(false);

  //Gesamt-Ergebnisse - stollte nicht im local Storage sein, wird vom Server geladen
  const [ergebnisse, setErgebnisse] = useState(null);
  const [version, setVersion] = useState(1);

  //Feedback variable
  const [feedback, setFeedback] = useState();

  useEffect(() => {
    const ladeKonfiguration = async () => {
      try {
        const gemeindenJSON = (await fetchGemeinden()).data;
        setGemeindeName(
          gemeindenJSON[ausschuss].find((g) => g.kennzeichen === gemeinde).name
        );
      } catch (e) {
        console.error('Fehler beim Laden der Gemeinden: ' + e.message);
      }
      //Ausschuesse fetchen
      try {
        const ausschussJSON = (await fetchAusschuesse(ausschuss)).data;

        setAusschussName(ausschussJSON.name);
        setVollerAusschussName(ausschussJSON.vollerName);
      } catch (e) {
        console.error('Fehler beim Laden des Gutachterausschuss: ' + e.message);
      }
    };

    ladeKonfiguration();
  }, []);

  // === Helper Functions ===

  //get Frage by id
  const getFrage = (id) => {
    if (!fragen) {
      return null;
    }

    let frage = fragen.find((frage) => frage.id === id);

    if (!frage) {
      frage = getFehlerFrage(`Es wurde keine Frage mit der ID ${id} gefunden`);
    }

    return frage;
  };

  const getFehlerFrage = (beschreibung) => {
    let frage = getFrage('FEHLER');
    frage.beschreibung = beschreibung;
    return frage;
  };

  const werteRegelAusFuerNaechsteFrageId = () => {
    const regeln = frage.regeln;
    //Falls Regeln besteht werden diese ausgewertet
    //Wenn eine Regel zutrifft wird die Auswertung abgebrochen und die Atkion ausgeführt
    if (regeln && regeln.length > 0 && isRegelnActive) {
      let geheZu = null;

      //Regeln auswerten falls Antwort vorhanden
      for (let i in regeln) {
        let regel = regeln[i];

        if (regel.typ === 'WENN') {
          //prüfen ob Bedingungs-Wert im aktuellen Antworten-Werten Array vorhanden ist,
          //abbruch falls Wert vorhanden und rückgabe der GEHEZU FrageID

          //Matrix-Antworten
          let bedingung = regel.bedingung;

          if (typeof bedingung === 'object' && aktuelleAntwort) {
            //Prüfen ob Zeile vorhanden im Ergebnis und ob Wert gleich Spalten-Wert ist
            if (
              aktuelleAntwort[bedingung.zeile] &&
              Array.isArray(aktuelleAntwort[bedingung.zeile])
            ) {
              let antwortWert = aktuelleAntwort[bedingung.zeile][0];
              if (antwortWert === bedingung.spalte) {
                geheZu = regel.aktion.GEHEZU;
                break;
              }
            }

            /* normale Antworten */
          } else if (
            aktuelleAntwort &&
            Array.isArray(aktuelleAntwort.auswahlen) &&
            aktuelleAntwort.auswahlen.includes(regel.bedingung)
          ) {
            geheZu = regel.aktion.GEHEZU;
            break;
          }
        } else if (regel.typ === 'SONST') {
          geheZu = regel.aktion.GEHEZU; //keine Prüfung, direkt Aktion ausführen
          break;
        } else if (regel.typ === 'SKIP') {
          return findeNaechsteFrageOhneRegel();
        } else {
          console.error('Regeltyp ' + regel.typ + ' nicht implementiert');
        }
      }

      if (geheZu) {
        return geheZu;
      } else {
        console.error(
          'Keine Regel hat zugetroffen. Evtl. fehlt die SONST Regel in der Frage: ' +
            frageId
        );
      }
    } else {
      return findeNaechsteFrageOhneRegel();
    }
  };

  const findeNaechsteFrageOhneRegel = () => {
    let naechsteFragenId = frageId;
    let naechsteFrage;
    let neuerIndex;
    do {
      //Index im Array der aktuellen Frage + 1
      neuerIndex =
        fragen.findIndex((frage) => frage.id === naechsteFragenId) + 1;

      //ende
      if (neuerIndex >= fragen.length) {
        return frageId;
      }
      naechsteFrage = fragen[neuerIndex];
      naechsteFragenId = naechsteFrage.id;
    } while (isRegelnActive && sollFrageGeskipptWerden(naechsteFrage));
    return naechsteFragenId;
  };

  const sollFrageGeskipptWerden = (frage) => {
    if (frage.regeln) {
      for (let i in frage.regeln) {
        let regel = frage.regeln[i];
        if (regel.typ && regel.typ === 'SKIP')
          if (
            regel.bedingung &&
            regel.bedingung.frage &&
            regel.bedingung.zeile &&
            regel.bedingung.spalte
          )
            if (
              checkIfMatrixAntwortInErgebnis(
                regel.bedingung.frage,
                regel.bedingung.zeile,
                regel.bedingung.spalte
              )
            ) {
              loescheErgebnisFürFrage(frage.id);
              return true;
            }
      }
    }
    return false;
  };

  const checkIfMatrixAntwortInErgebnis = (frage, zeile, spalte) => {
    if (frage === frageId) {
      //Wenn die aktuelleAntwort noch nicht in das Ergebnis gespeichert wurde
      if (aktuelleAntwort && aktuelleAntwort[zeile]) {
        return aktuelleAntwort[zeile].includes(spalte);
      }
    } else {
      // Wenn die Frage schon im ergebnis gespeichert wurde
      if (ergebnisse) {
        const var1 = ergebnisse[frage];
        if (var1) {
          const var3 = ergebnisse[frage][zeile];
          if (var3) {
            return ergebnisse[frage][zeile].includes(spalte);
          }
        }
      }
    }
    return false;
  };

  const deleteAntwortFromErgebnisse = (frageId) => {
    setErgebnisse((prevErgebnisse) => {
      delete prevErgebnisse[frageId];
      return prevErgebnisse;
    });
  };

  const loescheErgebnisFürFrage = (frageId) => {
    if (ergebnisse) {
      if (ergebnisse[frageId]) {
        deleteAntwortFromErgebnisse(frageId);
      }
    }
  };

  const getLetzteFrageId = (anzahlDerRueckschritte = 1) => {
    if (letzteFragenIds && letzteFragenIds.length > 0) {
      if (letzteFragenIds.length >= anzahlDerRueckschritte) {
        return letzteFragenIds[letzteFragenIds.length - anzahlDerRueckschritte];
      } else {
        //Wenn nichts gefunden wurde gehe zurück zur ersten Frage
        return '01-01-01';
      }
    } else {
      return;
    }
  };

  //Fügt FrageBlock 3 hinzu
  const insertFrageBlock3 = (block12GebaeudeArt, block3GebaeudeArt) => {
    const index = block12GebaeudeArt.findIndex(
      (frage) => frage.fragenblock === 3 || frage.fragenblock === '3'
    );

    //Platzhalter nicht gefunden oder keine Fragen in Block 3
    if (index === -1) {
      return block12GebaeudeArt;
    }

    if (!block3GebaeudeArt || !Array.isArray(block3GebaeudeArt)) {
      //Ohne Platzhalter Block 3 zurückgeben
      return [
        // Bis zum Platzhalter
        ...block12GebaeudeArt.slice(0, index),
        // Nach dem Platzhalter
        ...block12GebaeudeArt.slice(index + 1),
      ];
    } else {
      //Block 3 hinzufügen
      return [
        // Bis zum Platzhalter
        ...block12GebaeudeArt.slice(0, index),
        // Block 3
        ...block3GebaeudeArt,
        // Nach dem Platzhalter
        ...block12GebaeudeArt.slice(index + 1),
      ];
    }
  };

  //Frageblock vom Server holen falls Fragen noch nicht gesetzt wurden
  useEffect(() => {
    const ladeFragenbloecke = async () => {
      if (!fragen) {
        //Block 1+2 fetchen
        const block12response = await fetchFragen12(
          ausschuss,
          gemeinde,
          pruefCode,
          regNummer
        ).catch((e) => console.error(e));

        let block12;

        if (block12response && block12response.data) {
          block12 = block12response.data;
        } else {
          setNichtGefunden(true);
          return;
        }

        //Block 3 je nach Auschuss laden
        const block3response = await fetchFragen3(
          ausschuss,
          gemeinde,
          pruefCode,
          regNummer
        ).catch((e) => console.error(e));

        let block3 = {};

        if (block3response && block3response.data) {
          block3 = block3response.data;
        }

        let efh = block12.EFH;
        let etw = block12.ETW;
        let mfh = block12.MFH;

        //Block 3 hinzufügen
        if (block12 && block3) {
          efh = insertFrageBlock3(block12.EFH, block3.EFH);
          etw = insertFrageBlock3(block12.ETW, block3.ETW);
          mfh = insertFrageBlock3(block12.MFH, block3.MFH);

          //Fragenbool aus allen Frageblöcken zusammenstellen
          let fragenGesamt = [
            ...block12.START,
            ...efh,
            ...etw,
            ...mfh,
            ...block12.ENDE,
          ];

          //Fragenpool setzen, vorher inaktive Fragen filtern
          setFragen(
            fragenGesamt.filter((frage) => {
              if (
                frage.hasOwnProperty('istInaktiv') &&
                frage.istInaktiv === 'ja'
              ) {
                return false; //Frage herausfiltern
              } else {
                return true;
              }
            })
          );
        }
      }
    };

    ladeFragenbloecke();
  }, []);

  //Prüfe ob Gebäudeart gesetzt ist und ggf. aus Antworten heraus setzen (DeviceWechsel)
  useEffect(() => {
    if (!gebaeudeArt && ergebnisse && ergebnisse['01-01-01']) {
      const anwort = ergebnisse['01-01-01']; //im ersten Ergebnis steht die Antwort zur Gebäudeart

      if (anwort) {
        const anwortWert = anwort.auswahlen[0];
        const ersteFrage = getFrage('01-01-01');
        const antwortOption = getAntwortOptionByAntwortWert(
          ersteFrage,
          anwortWert
        ); //Antwortoption der ersten Fragen

        if (antwortOption) {
          setGebaeudeArt(antwortOption.text);
        }
      }
    }
  }, [ergebnisse]);

  //Wechsele Frage wenn FrageID sich ändert - Fragen hängen von gebaeudeArt ab
  useEffect(() => {
    if (fragen && frageId) {
      const neueFrage = getFrage(frageId);
      setFrage(neueFrage);

      //Bei Fragenwechsel History updaten
      history.push({
        pathname: `/frage/${frageId}`,
        search: query,
      });
    }
  }, [frageId, fragen]);

  //Rufe Ergebnisse vom Server ab
  const ladeErgebnisse = async (setAktuelleFrageFromServer = true) => {
    let response = await fetchErgebnisse(
      ausschuss,
      gemeinde,
      pruefCode,
      regNummer
    ).catch((e) => console.error(e));

    if (!response) {
      return;
    }

    //Lade gespeicherte Ergebenisse
    const ergebnisseVomServer = response.data;

    if (ergebnisseVomServer) {
      //Setze Ergebnisse falls vorhanden
      if (ergebnisseVomServer.ergebnisse) {
        if (setAktuelleFrageFromServer) {
          setzeErgebnisseVomServer(
            ergebnisseVomServer.ergebnisse,
            ergebnisseVomServer.version,
            ergebnisseVomServer.letzteFragenIds,
            ergebnisseVomServer.aktuelleFrageId
          );
        } else {
          setzeErgebnisseVomServer(
            ergebnisseVomServer.ergebnisse,
            ergebnisseVomServer.version
            /*Die letzte FragenID wird nicht gesetzt, weil wenn man zurück geht,
             das neue Ergebnis vom Server das Pop von dem letzteFrageId array beim zurück gehen überschreibt.*/
            // Die aktuelleFrageId wird auch nicht vom Server genommen, da diese sich ja auch nicht ändert.
          );
        }
      } else if (ergebnisseVomServer.version) {
        //Setze neue Versionnummer (Server-Version + 1)
        setVersion(ergebnisseVomServer.version + 1);
      }
    }
  };
  useEffect(() => {
    //nur Ergebisse vom Server laden falls keine im State
    if (!ergebnisse && fragen) {
      ladeErgebnisse();
    }
  }, [frageId, fragen]);

  // Dieser useEffect pollt die Ergebnisse bei den Hochladen Fragen,
  // damit der Nutzer auf dem Desktop sehen kann, dass die Datei hochladen wurde.
  useEffect(() => {
    let interval = -1;
    if (frage?.typ === 'hochladen') {
      interval = setInterval(() => {
        ladeErgebnisse(false);
      }, 2000);
    }
    return () => clearInterval(interval);
  }, [frage]);

  //Setze die nächsete FrageId indem die Regeln der aktiven Frage ausgewertet werden
  useEffect(() => {
    if (frage) {
      let nfrageId = werteRegelAusFuerNaechsteFrageId();
      setNaechsteFrageId(nfrageId);
    }
  }, [aktuelleAntwort, frage]);

  useEffect(() => {
    //z.B. für Uploads -> Ergebnis wird sofort übertragen
    if (ergebnisSofortSenden) {
      handleSubmit({ bleibeBeiAktuellerFrageId: true });
    }
  }, [aktuelleAntwort]);

  //Antwort speichern, nutzerEingabe relevant für Textfeld-, Nummer-, Datumseingaben
  const returnAntwortOnChange = (antwort, ergebnisSofortSpeichern = false) => {
    setErgebnisSofortSenden(ergebnisSofortSpeichern);
    setAktuelleAntwort(antwort);
  };

  //get AntwortOptionen by antwortWert
  const getAntwortOptionByAntwortWert = (frage, antwortWert) => {
    return frage.antwortOptionen.find((option) => option.wert === antwortWert);
  };

  //Setze neuere Ergebnisse und dessen Version
  const setzeErgebnisseVomServer = (
    neuereErgebnisse,
    versionAufDemServer,
    letzteFragenIdsVomServer,
    aktuelleFrageIdVomServer
  ) => {
    if (neuereErgebnisse) {
      setErgebnisse(neuereErgebnisse);
    }

    if (versionAufDemServer) {
      setVersion(versionAufDemServer);
    }

    if (letzteFragenIdsVomServer) {
      setLetzteFragenIds(letzteFragenIdsVomServer);
    }

    if (aktuelleFrageIdVomServer) {
      setFrageId(aktuelleFrageIdVomServer);
    }
  };

  //Sonderregel erste Frage
  const istWeiterButtonInaktiv = () => {
    if (frageId === '01-01-01') {
      if (aktuelleAntwort && aktuelleAntwort.auswahlen.length === 0) {
        return true;
      }
    }

    return false;
  };

  //bei Weiter wird Antwort gespeichert
  const handleSubmit = ({
    bleibeBeiAktuellerFrageId = false,
    abgabe = false,
  }) => {
    const sendeErgebnisseZumServer = async (ergebnisse) => {
      const response = await sendErgebnisse(
        ausschuss,
        gemeinde,
        pruefCode,
        regNummer,
        ergebnisse,
        abgabe
      ).catch((e) => console.error('Error: ', e.message));

      const result = response ? response.data : {};

      //Server gibt gespeicherte Version zurück falls Speicher erfolgreich, neue Version setzen
      if (result.ergebnisse) {
        //Server gibt Ergebnisse zurück falls diese neuer sind als die gesendeten
        setzeErgebnisseVomServer(
          result.ergebnisse,
          result.version,
          result.letzteFragenIds,
          result.aktuelleFrageId
        );
      } else if (result.version) {
        setVersion(result.version); //Speichern erfolgreich -> neue Versions
      }
    };

    const sendeFeedbackZumServer = async (feedback) => {
      const response = await sendFeedback(
        ausschuss,
        gemeinde,
        pruefCode,
        regNummer,
        feedback
      ).catch((e) => console.error('Error: ', e.message));
    };

    const checkIfAntwortIstLeer = (aktuelleAntwort) => {
      const aktuelleAntwortJSON = JSON.stringify(aktuelleAntwort);
      const blacklist = [
        JSON.stringify({ eingaben: '' }),
        JSON.stringify({ auswahlen: [''] }),
        JSON.stringify({ auswahlen: [null] }),
      ];
      return blacklist.includes(aktuelleAntwortJSON);
    };

    //Antwort im Ergebnis Objekt speichern. Object-Key ist die FrageId
    setErgebnisse((prevErgebnisse) => {
      if (aktuelleAntwort) {
        prevErgebnisse[frageId] = aktuelleAntwort;
      }

      if (checkIfAntwortIstLeer(aktuelleAntwort)) {
        console.error('Antwort ist leer: ', aktuelleAntwort);
      }

      //Hänge die aktuelle Frage nicht bei dem letzenFragenArray dran wenn die Frage nicht gewechselt werden soll
      let newLetzteFragenIds = letzteFragenIds;
      if (!bleibeBeiAktuellerFrageId) {
        //Setze neues letzte FrageIds Array
        newLetzteFragenIds = [...letzteFragenIds, frageId];
        setLetzteFragenIds(newLetzteFragenIds);
      }

      const ergebnis = {
        ausschussNr: ausschuss,
        gemeindeNr: gemeinde,
        pruefCode: pruefCode,
        regNr: regNummer,
        version: version,
        ergebnisse: prevErgebnisse,
        gebaeudeArt: gebaeudeArt,
        letzteFragenIds: newLetzteFragenIds,
        aktuelleFrageId: bleibeBeiAktuellerFrageId ? frageId : naechsteFrageId,
      };

      sendeErgebnisseZumServer(ergebnis);

      if (frage.typ === 'feedback') {
        let feedbackKomplett = Object.assign({}, feedback);
        feedbackKomplett['gebaeudeArt'] = gebaeudeArt;
        feedbackKomplett['ausschussName'] = ausschussName;
        feedbackKomplett['vollerAusschussName'] = vollerAusschussName;
        feedbackKomplett['gemeindeName'] = gemeindeName;
        sendeFeedbackZumServer(feedbackKomplett);
      }

      return prevErgebnisse;
    });
  };

  const loescheNichtBesuchteFragen = () => {
    //Alle antworten, die nicht im letzteFrageIds Array sind, werden gelöscht.

    //Die aktuelle Frage soll auch nicht gelöscht werden
    const newLetzteFragenIds = [...letzteFragenIds, frageId];
    for (let ergebniskey in ergebnisse) {
      if (!newLetzteFragenIds.some((frageId) => frageId === ergebniskey)) {
        console.log(
          'Lösche Ergebniskey: ' +
            ergebniskey +
            ', da nicht besucht siehe: ' +
            newLetzteFragenIds
        );
        deleteAntwortFromErgebnisse(ergebniskey);
      }
    }
  };
  const handleWeiterClickMitAbgabe = () => handleWeiterClick({ abgeben: true });

  //Steuerung der Navigation (vor/zurück)
  const handleWeiterClick = ({ abgeben = false }) => {
    //Gebäudeaart setzen bei erste Frage
    if ('01-01-01' === frageId) {
      setGebaeudeArt(
        getAntwortOptionByAntwortWert(frage, aktuelleAntwort.auswahlen[0])?.text
      );
    }

    if ('ZUSAMMENFASSUNG' === naechsteFrageId) {
      //lösche die Antworten aller nicht besuchten Fragen.
      loescheNichtBesuchteFragen();
    }

    //FrageID auf die nächste Frage setzen
    setFrageId(naechsteFrageId);

    //Klick auf Weiter -> Speichern der Antwort
    handleSubmit({ abgabe: abgeben });

    //CleanUp
    setAktuelleAntwort(null);
  };

  const handleZurueckClick = (e, anzahlDerRueckschritte = 1) => {
    //anzahlDerRückschritte wird genutzt um z.B. 5 Schritte zurück zu gehen.

    //CleanUp
    setAktuelleAntwort(null);

    //FrageID auf die letzte Frage setzen
    setFrageId(getLetzteFrageId(anzahlDerRueckschritte));

    //FrageId aus Letzte Frage IDs Stack entfernen
    setLetzteFragenIds((prevLetzteFrageIds) => {
      const index = prevLetzteFrageIds.findIndex(
        (id) => id === getLetzteFrageId(anzahlDerRueckschritte)
      );
      let letzteFragen = prevLetzteFrageIds.slice(0, index);

      return letzteFragen;
    });
  };

  //Login nicht gefunden (404/Not Found)
  if (nichtGefunden) {
    return <Redirect to="/nichtgefunden" />;
  }

  if (fragen === null || frage === null || ergebnisse === null) {
    return <LoadingAnimation />;
  }

  return (
    <TwoRowsBox>
      {/* Fortschrittsbalken bei manchen FrageTypen ausblenden */}
      {frage?.typ !== 'zusammenfassung' &&
      frage?.typ !== 'feedback' &&
      frage?.typ !== 'einwilligung' ? (
        <Fortschrittsbalken frageID={frage?.id} alleFragen={fragen} />
      ) : (
        ''
      )}
      <UpperSide>
        <FrageKopf
          gebaeudeArt={gebaeudeArt}
          nummer={frage.id}
          frage={frage.frage}
          ueberschrift={frage.seite?.ueberschrift}
        />
      </UpperSide>
      <LowerSide>
        <div
          css={css`
            min-height: 300px;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            grid-gap: 0px 38px;
          `}
        >
          {/* aktuelle Frage je nach Typ darstellen */}
          <div
            css={css`
              @media (${breakpoints.minFHD}) {
                min-height: 30em;
              }
            `}
          >
            <FrageSwitch
              key={'Frageswitch' + version}
              frage={frage}
              returnAntwortOnChange={returnAntwortOnChange}
              ergebnisse={ergebnisse}
              setFeedbackFunc={setFeedback}
              gebaeudeArt={gebaeudeArt}
            />
          </div>
          <nav
            css={css`
              margin-top: 80px;
              display: grid;
              grid-template-columns: 1fr 1fr 1fr;
              grid-template-rows: auto;
              grid-template-areas: 'fuenfzurueck zurueck weiter';
              @media (${breakpoints.tablet}) {
                grid-template-columns: 1fr 1fr;
                grid-template-areas:
                  'zurueck weiter'
                  'fuenfzurueck fuenfzurueck';
              }
              grid-gap: 0px 38px;
              align-self: end;
              justify-self: center;
              width: 100%;
            `}
          >
            {!['danke', 'feedback'].includes(frage.typ) ? (
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  grid-area: fuenfzurueck;
                  justify-content: center;
                  @media (${breakpoints.tablet}) {
                    margin-top: 30px;
                  }
                `}
              >
                <HashLink
                  to={{
                    pathname: getLetzteFrageId(5)
                      ? `/frage/${getLetzteFrageId(5)}`
                      : '/',
                    search: query,
                    hash: '#fragen-start',
                  }}
                  role="button"
                  onClick={(e) => handleZurueckClick(e, 5)}
                  css={css`
                    color: ${colors.blue};
                  `}
                >
                  Fünf Seiten zurück
                </HashLink>
              </div>
            ) : null}
            {!['danke', 'feedback'].includes(frage.typ) ? (
              <Button
                handleClick={handleZurueckClick}
                link={{
                  pathname: getLetzteFrageId()
                    ? `/frage/${getLetzteFrageId()}`
                    : '/',
                  search: query,
                  hash: '#fragen-start',
                }}
                text="Zurück"
                invert={true}
              />
            ) : (
              ''
            )}
            {frage.istWeiterButtonInaktiv !== 'ja' ? (
              <Button
                disabled={istWeiterButtonInaktiv()}
                handleClick={
                  frage.typ === 'zusammenfassung'
                    ? handleWeiterClickMitAbgabe
                    : handleWeiterClick
                }
                link={{
                  pathname: `/frage/${naechsteFrageId}`,
                  search: query,
                  hash: '#fragen-start',
                }}
                text={
                  frage.typ === 'zusammenfassung' || frage.typ === 'feedback'
                    ? 'Absenden'
                    : 'Weiter'
                }
              />
            ) : null}
          </nav>
          {istWeiterButtonInaktiv() ? (
            <span
              css={css`
                padding-top: 30px;
                color: black;
              `}
              aria-live="polite"
            >
              Hinweis: Bitte beantworten Sie die Frage, um fortzufahren.
            </span>
          ) : null}
        </div>
      </LowerSide>
    </TwoRowsBox>
  );
}
