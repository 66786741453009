/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React from 'react'; // eslint-disable-line

import Einfachauswahl from '../components/AntwortKompositonen/Einfachauswahl';
import Mehrfachauswahl from '../components/AntwortKompositonen/Mehrfachauswahl';
import Eingabefeld from '../components/AntwortKompositonen/Eingabefeld';
import Einwilligung from '../components/AntwortKompositonen/Einwilligung';
import Matrix from '../components/AntwortKompositonen/Matrix';
import Zusammenfassung from '../components/AntwortKompositonen/Zusammenfassung';
import Danke from '../components/AntwortKompositonen/Danke';
import Schieberegler from '../components/AntwortKompositonen/Schieberegler';
import InteraktivesSchaubild from './AntwortKompositonen/InteraktivesSchaubild';
import Feedback from '../components/AntwortKompositonen/Feedback';
import Hochladen from '../components/AntwortElemente/Hochladen';
import Fehler from '../components/AntwortKompositonen/Fehler';

//Frage wird je nach Fragetyp gerendert
const FrageSwitch = ({
  frage,
  returnAntwortOnChange,
  ergebnisse,
  gebaeudeArt = 'Haus',
  setFeedbackFunc,
}) => {
  const images = '/images/';
  if (frage.typ === 'einfachauswahl') {
    return (
      <Einfachauswahl
        returnAntwortOnChange={returnAntwortOnChange}
        bisherigeAuswahlen={ergebnisse[frage.id]?.auswahlen}
        bisherigeEingaben={ergebnisse[frage.id]?.eingaben}
        frageId={frage.id}
        beschreibung={frage.beschreibung}
        antwortOptionen={frage.antwortOptionen}
        bilderPfad={images}
        key={frage.id}
        tooltipText={frage.tooltip}
        gebaeudeArt={gebaeudeArt}
      />
    );
  } else if (frage.typ === 'mehrfachauswahl') {
    return (
      <Mehrfachauswahl
        returnAntwortOnChange={returnAntwortOnChange}
        bisherigeAuswahlen={ergebnisse[frage.id]?.auswahlen}
        bisherigeEingaben={ergebnisse[frage.id]?.eingaben}
        frageId={frage.id}
        beschreibung={frage.beschreibung}
        antwortOptionen={frage.antwortOptionen}
        bilderPfad={images}
        key={frage.id}
        tooltipText={frage.tooltip}
        gebaeudeArt={gebaeudeArt}
      />
    );
  } else if (frage.typ === 'eingabefeld') {
    return (
      <Eingabefeld
        returnAntwortOnChange={returnAntwortOnChange}
        bisherigeEingaben={ergebnisse[frage.id]?.eingaben}
        frageId={frage.id}
        beschreibung={frage.beschreibung}
        format={frage.format}
        min={frage.min}
        max={frage.max}
        datumsformat={frage.datumsformat}
        symbol={frage.symbol}
        placeholder={frage.placeholder}
        key={frage.id}
        schritt={frage.schritt}
        antwortOptionen={frage.antwortOptionen}
        tooltipText={frage.tooltip}
        gebaeudeArt={gebaeudeArt}
      />
    );
  } else if (frage.typ === 'hochladen' || frage.typ === 'wohneinheiten') {
    return (
      <Hochladen
        antwortOptionen={frage.antwortOptionen}
        beschreibung={frage.beschreibung}
        frageId={frage.id}
        returnAntwortOnChange={returnAntwortOnChange}
        ergebnisse={ergebnisse[frage.id]}
        tooltipText={frage.tooltip}
        gebaeudeArt={gebaeudeArt}
        erlaubeAlleFormate={frage.erlaubeAlleFormate === 'ja'}
        maximalErlaubteDateien={frage.maximalErlaubteDateien || 1}
      />
    );
  } else if (frage.typ === 'matrix') {
    return (
      <Matrix
        beschreibung={frage.beschreibung}
        frageId={frage.id}
        returnAntwortOnChange={returnAntwortOnChange}
        bisherigeAuswahlen={ergebnisse[frage.id]}
        spalten={frage.spalten}
        dynamischeSpalten={frage.dynamischeSpalten}
        zeilen={frage.zeilen}
        zelltyp={frage.zelltyp}
        ergebnis={ergebnisse}
        key={frage.id}
        tooltipText={frage.tooltip}
        gebaeudeArt={gebaeudeArt}
      />
    );
  } else if (frage.typ === 'schieberegler') {
    return (
      <Schieberegler
        returnAntwortOnChange={returnAntwortOnChange}
        bisherigeEingaben={ergebnisse[frage.id]?.eingaben}
        format={frage.format}
        frageId={frage.id}
        min={frage?.min}
        max={frage.max}
        schritt={frage.schritt}
        labels={frage?.labels}
        beschreibung={frage.beschreibung}
        tooltipText={frage.tooltip}
        gebaeudeArt={gebaeudeArt}
      />
    );
  } else if (frage.typ === 'interaktiv') {
    return (
      <InteraktivesSchaubild
        returnAntwortOnChange={returnAntwortOnChange}
        bisherigeAuswahlen={ergebnisse[frage.id]?.auswahlen}
        bisherigeEingaben={ergebnisse[frage.id]?.eingaben}
        frageId={frage.id}
        beschreibung={frage.beschreibung}
        schaubild={frage.schaubild}
        format={frage.format}
        key={frage.id}
        gebaeudeArt={gebaeudeArt}
        tooltipText={frage.tooltip}
        maxAnzahlObergeschosse={frage.maxAnzahlObergeschosse}
        maxAnzahlStaffelgeschosse={frage.maxAnzahlStaffelgeschosse}
      />
    );
  } else if (frage.typ === 'einwilligung') {
    return (
      <Einwilligung
        returnAntwortOnChange={returnAntwortOnChange}
        bisherigeAuswahlen={ergebnisse[frage.id]?.auswahlen}
        bisherigeEingaben={ergebnisse[frage.id]?.eingaben}
        seite={frage.seite}
      />
    );
  } else if (frage.typ === 'zusammenfassung') {
    return <Zusammenfassung ergebnisse={ergebnisse} seite={frage.seite} />;
  } else if (frage.typ === 'feedback') {
    return <Feedback seite={frage.seite} setFeedbackFunc={setFeedbackFunc} />;
  } else if (frage.typ === 'danke') {
    return <Danke seite={frage.seite} />;
  } else if (frage.typ === 'fehler') {
    return <Fehler beschreibung={frage.beschreibung} seite={frage.seite} />;
  } else {
    return <div>Fragetyp {frage.typ} wurde nicht gefunden</div>;
  }
};

export default FrageSwitch;
