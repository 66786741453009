/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

import React, { useState } from 'react';
import Button from '../../components/Button';
import { breakpoints } from '../../styles/breakpoints';

export default function Danke({ seite }) {
  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: seite.htmlBlock }} />
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: left;
          @media (${breakpoints.tablet}) {
            margin-top: 30px;
          }
        `}
      >
        {seite.buttonText && seite.buttonLink ? (
          <Button
            absolute={true}
            link={seite.buttonLink}
            text={seite.buttonText}
          />
        ) : (
          ''
        )}
      </div>
    </>
  );
}
