/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React from 'react';

import Header from './Header';
import Footer from './Footer';

import colors from '../../styles/colors.js';

export default function Layout(props) {
  return (
    <React.Fragment>
      <div
        css={css`
          display: grid;
          grid-template-columns: 1fr;
          /* 3 sections : header main footer */
          grid-template-rows: 0.5fr 6fr 0.5fr;
          height: 100vh;
          margin: 0;
        `}
      >
        <Header />
        <main>
            {props.children}
        </main>
        <Footer />
      </div>
    </React.Fragment>
  );
}
