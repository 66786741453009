/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Button from '../components/Button';

import LowerSide from '../components/Layout/LowerSide';
import TwoRowsBox from '../components/Layout/TwoRowsBox';
import { fetchSeitenDaten } from '../service/fetchConfig';
import { ENDPOINTS } from '../service/api';

export default function NichtGefunden() {
  //Get Parameter
  const [seitenDaten, setSeitenDaten] = useState();

  useEffect(() => {
    const ladeSeitenDaten = async () => {
      const seitenDaten = (await fetchSeitenDaten(ENDPOINTS.NICHTGEFUNDENSEITE))
        .data;
      setSeitenDaten(seitenDaten);
    };

    ladeSeitenDaten();
  }, []);

  return (
    <TwoRowsBox>
      <LowerSide>
        <h1>Nicht Gefunden</h1>
        {seitenDaten && (
          <div dangerouslySetInnerHTML={{ __html: seitenDaten.text1 }} />
        )}
        <div
          css={css`
            margin-top: 40px;
          `}
        >
          <Button link={`/`} text="Zurück zur Startseite" />
        </div>
      </LowerSide>
    </TwoRowsBox>
  );
}
