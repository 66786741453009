/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React from 'react'; // eslint-disable-line

import colors from '../../styles/colors.js';
import { breakpoints } from '../../styles/breakpoints';

const dektopLeftRightSpace = '25%';
const smallDektopLeftRightSpace = '20%';
const tabletLeftRightSpace = '4%';
const mobilepLeftRightSpace = '10px';

const LowerSide = (props) => (
  <div
    css={css`
      color: ${colors.blue};
      display: grid;
      /* [weißer space links] [content] [weißer space rechts]  */
      grid-template-columns: ${dektopLeftRightSpace} auto ${dektopLeftRightSpace};
      /* [weißer space oben] [content] [weißer space unten]  */
      grid-template-rows: 40px auto 100px;

      @media (max-width: 1100px) {
        grid-template-columns: ${smallDektopLeftRightSpace} auto ${smallDektopLeftRightSpace};
        grid-template-rows: 40px auto 40px;
      }

      @media (${breakpoints.tablet}) {
        grid-template-columns: ${tabletLeftRightSpace} auto ${tabletLeftRightSpace};
        grid-template-rows: 15px auto 100px;
      }

      @media (${breakpoints.smartphone}) {
        /* nur noch sehr schmaler Rand bei mobile view */
        grid-template-columns: ${mobilepLeftRightSpace} auto ${mobilepLeftRightSpace};
      }
    `}
  >
    <div
      css={css`
        grid-row: 1/2;
      `}
    ></div>
    <div
      css={css`
        grid-column: 2/3;
        grid-row: 2/3;
      `}
    >
      {props.children}
    </div>
    <div
      css={css`
        grid-row: 3/3;
      `}
    ></div>
  </div>
);

export default LowerSide;
