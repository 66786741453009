/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

import React, { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import Textbereich from '../AntwortElemente/Textbereich';
import Textfeld from '../AntwortElemente/Textfeld';
import Schieberegler from './Schieberegler';
import Button from '../Button';

export default function Feedback({ seite, setFeedbackFunc }) {
  const apiHost = process.env.REACT_APP_API_HOST;

  const [kommentar, setKommentar] = React.useState(null);
  const [schiebereglerWert, setSchiebereglerWert] = React.useState(null);

  useEffect(() => {
    let bewertung;
    if (schiebereglerWert && schiebereglerWert.eingaben) {
      bewertung = schiebereglerWert?.eingaben['01'];
    } else {
      bewertung = null;
    }
    setFeedbackFunc({
      bewertung: bewertung,
      kommentar: kommentar
    });
  }, [kommentar, schiebereglerWert]);
  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: 1.5fr 1fr;
        grid-template-rows: auto;
        grid-gap: 30px 100px;
        grid-template-areas:
          'htmlBlock htmlBlock'
          'schieberegler schieberegler'
          'eingabe eingabe';

        @media (max-width: 800px) {
          grid-template-columns: 1fr;
          grid-template-areas:
            'htmlBlock'
            'schieberegler'
            'eingabe';
        }
      `}
    >
      {/* Feedback */}
      <div
        css={css`
          grid-area: htmlBlock;
          padding-top: 20px;
        `}
        dangerouslySetInnerHTML={{ __html: seite.htmlBlock }}
      />
      <div
        css={css`
          grid-area: schieberegler;
        `}
      >
        <Schieberegler
          returnAntwortOnChange={(wert) => setSchiebereglerWert(wert)}
          frageId={'feedback'}
          beschreibung={''}
          min={1}
          max={5}
          schritt={0.5}
          labels={[
            'sehr unzufrieden',
            'unzufrieden',
            'neutral',
            'zufrieden',
            'sehr zufrieden',
          ]}
        />
      </div>
      <div
        css={css`
          grid-area: eingabe;
        `}
      >
        <Textbereich
          handleElementChange={(wert) => setKommentar(wert)}
          value={''}
          label="Kommentar"
          rows={6}
        />
      </div>
    </div>
  );
}
