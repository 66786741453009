/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import styles from './Answer.module.css';
import React, { useState } from 'react';

const NumberAnswer = ({
  value = '',
  symbol = '',
  min,
  max,
  placeholder,
  handleElementChange,
  schritt = 1,
  eingabefeldBeschreibung,
}) => {
  const [nummer, setNummer] = useState(value);
  const [showError, setShowError] = useState(false);

  if (!min) {
    min = 0;
  }
  if (!max) {
    max = 999999999;
  }

  const handleInputChange = (e) => {
    let antwortWert;
    if (e.target.value != '') {
      antwortWert = String(Number(e.target.value));
      if (antwortWert === 'NaN') {
        antwortWert = '';
      }
    } else {
      antwortWert = '';
    }
    setNummer(antwortWert);
    handleElementChange(antwortWert);

    if (antwortWert < min || antwortWert > max) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  };

  return (
    <>
      {eingabefeldBeschreibung && <div>{eingabefeldBeschreibung}</div>}
      <div
        css={css`
          display: grid;
          grid-template-columns: 8% 1fr;
          grid-template-rows: 50px 1fr;
          gap: 0px 0px;
          ${symbol
            ? `grid-template-areas:
            'icon inputfield'
            'invalidmessage invalidmessage';`
            : `grid-template-areas:
            'inputfield inputfield'
            'invalidmessage invalidmessage'; `}
        `}
      >
        {symbol ? (
          <div className={styles.number_icon_container}>
            <p className={styles.number_icon}>{symbol}</p>
          </div>
        ) : null}
        <input
          className={styles.number_input}
          value={nummer}
          type="number"
          aria-label={
            eingabefeldBeschreibung ? eingabefeldBeschreibung : 'Zahleneingabe'
          }
          step={schritt}
          min={min}
          max={max}
          onChange={(e) => handleInputChange(e)}
          placeholder={placeholder}
        />
        {showError && (
          <p className={styles.number_input_invalid_p}>
            Der eingegebene Wert entspricht nicht den Vorgaben. Bitte überprüfen
            Sie ihn.
          </p>
        )}
      </div>
    </>
  );
};
export default NumberAnswer;
