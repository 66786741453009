/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import styles from '../AntwortElemente/Answer.module.css';
import { useState, useEffect, React } from 'react';
import AuswahlReset from './AuswahlReset';

import BeschreibungMitTooltip from '../AntwortElemente/BeschreibungMitTooltip';

export default function Matrix({
  frageId,
  beschreibung,
  returnAntwortOnChange,
  bisherigeAuswahlen,
  zeilen,
  dynamischeSpalten,
  spalten,
  zelltyp,
  ergebnis,
  tooltipText,
  gebaeudeArt,
}) {
  //Wenn das Limit zu groß ist und der Benutzer eine zu große Spaltenzahl eingibt, kann der PC des Benutzers einfrieren.
  const technischesSpaltenLimit = 100;
  const [auswahlen, setAuswahlen] = useState(
    bisherigeAuswahlen ? bisherigeAuswahlen : {}
  );

  useEffect(() => {
    returnAntwortOnChange(auswahlen);
  }, [auswahlen]);

  function arrayRemove(arr, value) {
    return arr.filter(function (ele) {
      return ele !== value;
    });
  }

  const radioAntwortFunktion = (zeile, spalte) => {
    setAuswahlen({
      ...auswahlen,
      [zeile.wert]: [String(spalte.wert)],
    });
  };

  const checkboxAntwortFunktion = (zeile, spalte) => {
    if (zeile.wert in auswahlen && auswahlen[zeile.wert] !== []) {
      if (auswahlen[zeile.wert].includes(String(spalte.wert))) {
        setAuswahlen({
          ...auswahlen,
          [zeile.wert]: arrayRemove(auswahlen[zeile.wert], String(spalte.wert)),
        });
      } else {
        setAuswahlen({
          ...auswahlen,
          [zeile.wert]: auswahlen[zeile.wert]
            .concat([String(spalte.wert)])
            .sort(),
        });
      }
    } else {
      setAuswahlen({
        ...auswahlen,
        [zeile.wert]: [String(spalte.wert)],
      });
    }
  };

  const Zeile = ({ zeile, spalten }) => (
    <tr className={styles.matrix_row} key={zeile.wert}>
      <th className={styles.matrix_first_column}>
        <p className={styles.matrix_font}>{zeile.text} </p>
      </th>
      {spalten
        ? spalten.map((spalte, i) => (
            <td className={styles.matrix_cell} key={'Spaltenkey' + i}>
              {zelltyp === 'mehrfachauswahl' ? (
                <input
                  type="checkbox"
                  name={frageId + zeile.wert + spalte.wert}
                  checked={
                    //die checked Funktion darf nicht in eine andere Funktion ausgelagert werden,
                    // da sonst die Statechanges in auswahlen nicht erkannt werden.
                    zeile.wert in auswahlen
                      ? auswahlen[zeile.wert].includes(String(spalte.wert))
                      : false
                  }
                  onChange={() => checkboxAntwortFunktion(zeile, spalte)}
                />
              ) : zelltyp === 'einfachauswahl' ? (
                <input
                  type="radio"
                  name={frageId + zeile.wert + spalte.wert}
                  checked={
                    //die checked Funktion darf nicht in eine andere Funktion ausgelagert werden,
                    // da sonst die Statechanges in auswahlen nicht erkannt werden.
                    zeile.wert in auswahlen
                      ? auswahlen[zeile.wert].includes(String(spalte.wert))
                      : false
                  }
                  onChange={() => radioAntwortFunktion(zeile, spalte)}
                />
              ) : (
                <p>
                  {zelltyp
                    ? 'Zelltyp: ' + zelltyp + ' nicht gefunden'
                    : 'Kein Zelltyp angegeben'}
                </p>
              )}
            </td>
          ))
        : null}
    </tr>
  );

  const SpaltenHeader = ({ spalten }) => (
    <tr>
      <th className={styles.matrix_first_row} />
      {spalten
        ? spalten.map((spalte, i) => (
            <th key={'SpaltenHeader' + i} className={styles.matrix_first_row}>
              <p className={styles.matrix_font}>{spalte.text}</p>
            </th>
          ))
        : null}
    </tr>
  );

  //berechne Anzahl dynamischer Zeilen
  let anzahlDynamischerSpalten;
  let dynamischeSpaltenArray;

  if (dynamischeSpalten) {
    try {
      anzahlDynamischerSpalten = parseInt(
        ergebnis[dynamischeSpalten.anzahl.wertVon].eingaben[
          Object.keys(ergebnis[dynamischeSpalten.anzahl.wertVon].eingaben)[0]
        ]
      );
      if (anzahlDynamischerSpalten <= technischesSpaltenLimit) {
        dynamischeSpaltenArray = [...Array(anzahlDynamischerSpalten)].map(
          (x, i) => {
            return {
              wert: i + 1,
              text: dynamischeSpalten.textPraefix + (i + 1),
            };
          }
        );
      }
    } catch (e) {}
  }

  const setzeRatingZurueck = () => {
    setAuswahlen({});
  };

  return (
    <div
      css={css`
        overflow-x: auto;
      `}
    >
      <div
        css={css`
          padding-bottom: 10px;
        `}
      >
        <BeschreibungMitTooltip
          beschreibung={beschreibung}
          tooltipText={tooltipText}
          tooltipID={frageId}
          gebaeudeArt={gebaeudeArt}
        />
      </div>
      {dynamischeSpalten &&
      !anzahlDynamischerSpalten &&
      (!dynamischeSpaltenArray || dynamischeSpaltenArray.length === 0) ? (
        <p>
          {dynamischeSpalten?.anzahl?.wertVon
            ? `Bevor sie diese Frage ausfüllen können, füllen Sie bitte Frage ${dynamischeSpalten?.anzahl?.wertVon} aus.`
            : 'Bitte definieren Sie, welche Frage vorher beantwortet werden muss.'}
        </p>
      ) : null}
      {anzahlDynamischerSpalten &&
      anzahlDynamischerSpalten > technischesSpaltenLimit ? (
        <p>
          Bitte wählen Sie bei Frage {dynamischeSpalten?.anzahl?.wertVon + ' '}
          einen kleineren Wert aus.
        </p>
      ) : (
        <table className={styles.matrix}>
          <tbody>
            {
              //Zeige den Tabellenheader nur an, wenn die Tabelle auch dargestellt werden kann.
              spalten ? (
                <SpaltenHeader spalten={spalten} />
              ) : dynamischeSpalten &&
                anzahlDynamischerSpalten &&
                dynamischeSpaltenArray ? ( //Wenn dynamische Spalten angegeben sind und die werte gefunden wurden nutze das erstelle Spaltenarray
                <SpaltenHeader spalten={dynamischeSpaltenArray} />
              ) : null
            }
            {zeilen
              ? //wurden überhaupt zeilen angegeben?
                zeilen.map((zeile, i) =>
                  dynamischeSpalten ? (
                    //Nutze dynamische Spalten?
                    anzahlDynamischerSpalten && dynamischeSpaltenArray ? (
                      <Zeile
                        zeile={zeile}
                        spalten={dynamischeSpaltenArray}
                        key={'FesteZeileMitDynamischenSpalten' + i}
                      />
                    ) : //Wenn die dyn Spalten nicht berechnet werden können wird nur der Hinweis angezeigt
                    null
                  ) : (
                    <Zeile
                      zeile={zeile}
                      spalten={spalten}
                      key={'FesteZeile' + i}
                    />
                  )
                )
              : null}

            {!spalten && !dynamischeSpalten ? (
              <p>Es wurden keine Spalten in der Frage definiert.</p>
            ) : null}
          </tbody>
        </table>
      )}
      {zelltyp === 'einfachauswahl' && (
        <AuswahlReset zurueckSetzen={setzeRatingZurueck} />
      )}
    </div>
  );
}
