/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React from 'react';

import colors from '../../styles/colors.js';
import { breakpoints } from '../../styles/breakpoints';

export default function LeftRightBox(props) {
  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 0.3fr auto;
        height: 100%;
        border-top: 4px solid ${colors.blue};

        @media (${breakpoints.tablet}) {
        }
      `}
    >
      {props.children}
    </div>
  );
}
