/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React from 'react'; // eslint-disable-line

const getGenitiv = (s) => {
  if (typeof s !== 'string') {
    return s;
  } else {
    return s.replaceAll('haus', 'hauses');
  }
};

const FrageKopf = ({
  nummer,
  frage,
  typ,
  ueberschrift = null,
  gebaeudeArt = 'Haus',
}) => (
  <>
    <div
      css={css`
        border-bottom: ${ueberschrift ? '0px' : '2px'} solid #fff;
        padding: 10px 0px;
      `}
    >
      {ueberschrift ? (
        <div
          dangerouslySetInnerHTML={{
            __html: ueberschrift,
          }}
        ></div>
      ) : (
        'Frage ' + nummer
      )}
    </div>

    {frage ? (
      <div>
        <h2
          css={css`
            font-weight: 600;
            padding: 10px 0px;
            min-height: 60px;
            font-size: 16px;
          `}
        >
          {frage
            .replaceAll('[Gebäudeart;Genitiv]', getGenitiv(gebaeudeArt))
            .replaceAll('[Gebäudeart]', gebaeudeArt)}
        </h2>
      </div>
    ) : (
      ''
    )}
  </>
);

export default FrageKopf;
