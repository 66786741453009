import React from 'react';
import BeschreibungMitTooltip from '../AntwortElemente/BeschreibungMitTooltip';
import GeraeteWechsel from './GeraeteWechsel';
import MultiUpload from './MultiUpload';

const Hochladen = ({
  antwortOptionen,
  beschreibung,
  frageId,
  ergebnisse,
  returnAntwortOnChange,
  tooltipText,
  gebaeudeArt,
  erlaubeAlleFormate,
  maximalErlaubteDateien,
}) => {
  const ausgewaehlteDateien = ergebnisse?.dateien || {}; // Dateien die in diesem Schritt zuvor bereits auf den Server geladen wurden

  React.useEffect(() => {
    if (ergebnisse?.dateien) {
      returnAntwortOnChange({ dateien: ergebnisse.dateien });
    }
  }, []);

  const fragen = antwortOptionen || [
    {
      wert: '01',
      text: 'Upload',
    },
  ];

  return (
    <div>
      <GeraeteWechsel />

      {erlaubeAlleFormate ? (
        <p>Sie können das Dateiformat der hochzuladenden Datei frei wählen.</p>
      ) : (
        <p>
          Sie können die Dokumente/Fotos als PDF-, JPG- oder PNG-Datei
          hochladen.
        </p>
      )}

      <BeschreibungMitTooltip
        beschreibung={beschreibung}
        tooltipText={tooltipText}
        tooltipID={frageId}
        gebaeudeArt={gebaeudeArt}
      />

      {fragen.map(({ wert, text }, index) => (
        <MultiUpload
          ausgewaehlteDateien={ausgewaehlteDateien}
          erlaubeAlleFormate={erlaubeAlleFormate}
          frageId={frageId}
          id={wert}
          isLastElement={index === fragen.length - 1}
          key={'upload-files-' + index + frageId}
          returnAntwortOnChange={returnAntwortOnChange}
          text={text}
          maximalErlaubteDateien={maximalErlaubteDateien}
        />
      ))}
    </div>
  );
};

export default Hochladen;
