/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import Nummer from '../AntwortElemente/Nummer';
import Datum from '../AntwortElemente/Datum';
import Textbereich from '../AntwortElemente/Textbereich';
import { useState, useEffect } from 'react';

import BeschreibungMitTooltip from '../AntwortElemente/BeschreibungMitTooltip';

export default function Eingabefeld({
  format,
  frageId,
  datumsformat,
  beschreibung,
  returnAntwortOnChange,
  min,
  max,
  placeholder,
  symbol,
  bisherigeEingaben = {},
  schritt,
  antwortOptionen,
  tooltipText,
  gebaeudeArt,
}) {
  const [antworten, setAntworten] = useState(bisherigeEingaben);

  useEffect(() => {
    returnAntwortOnChange({ eingaben: antworten });
  }, [antworten]);

  const handleElementChangeFactory = (antwortWert) => {
    return (eingabe) => {
      if (eingabe) {
        setAntworten({ ...antworten, [antwortWert]: eingabe });
      } else {
        //delete empty answers
        let antwortenKopie = Object.assign({}, antworten);
        delete antwortenKopie[antwortWert];
        setAntworten(antwortenKopie);
      }
    };
  };

  const renderTextBoxWithFormat = (format, antwortWert, antwortText) => {
    if (format === 'zahl') {
      return (
        <Nummer
          symbol={symbol}
          value={bisherigeEingaben[antwortWert]}
          min={min}
          max={max}
          placeholder={placeholder ? placeholder : max ? `z.B. ${max}` : ''}
          handleElementChange={handleElementChangeFactory(antwortWert)}
          schritt={schritt}
          eingabefeldBeschreibung={antwortText}
          key={'Numbereingabe' + antwortWert + antwortText}
        />
      );
    } else if (format === 'datum') {
      return (
        <Datum
          value={bisherigeEingaben[antwortWert]}
          datumsformat={datumsformat}
          minJahr={min}
          maxJahr={max}
          handleElementChange={handleElementChangeFactory(antwortWert)}
          eingabefeldBeschreibung={antwortText}
          key={'Datumseingabe' + antwortWert + antwortText}
        />
      );
    } else if (format === 'text') {
      return (
        <Textbereich
          handleElementChange={handleElementChangeFactory(antwortWert)}
          value={bisherigeEingaben[antwortWert]}
          rows={6}
          key={'Textbereicheingabe' + antwortWert + antwortText}
          label={antwortText}
          placeholder={placeholder ? placeholder : ''}
        />
      );
    } else return <div>Textbox Format nicht gefunden</div>;
  };
  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-gap: 10px 38px;
      `}
    >
      {(beschreibung && beschreibung !== '') ||
      (tooltipText && tooltipText !== '') ? (
        <div
          css={css`
            padding-bottom: 0px;
          `}
        >
          <BeschreibungMitTooltip
            beschreibung={beschreibung}
            tooltipText={tooltipText}
            tooltipID={frageId}
            gebaeudeArt={gebaeudeArt}
          />
        </div>
      ) : null}

      {antwortOptionen
        ? antwortOptionen.map((antwortOption) =>
            renderTextBoxWithFormat(
              format,
              antwortOption.wert,
              antwortOption.text
            )
          )
        : //da wir immer nur 1 Eingabefeld haben ist der antwortWert 01 fest
          renderTextBoxWithFormat(format, '01')}
    </div>
  );
}
