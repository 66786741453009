/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState } from 'react';
import styles from './Answer.module.css';
import colors from '../../styles/colors.js';
import { DateTime } from 'luxon';

import { jsx, css } from '@emotion/react';

import InputMask from 'react-input-mask';

const iconPath = '/images/calendar.svg';
const altText = 'Ein Icon das einen Kalender darstellt.';

function renderInputByRequestedDateFormat(
  datumsformat,
  datum,
  handleAntwortChange,
  eingabefeldBeschreibung
) {
  if (datumsformat === 'dd-mm-yyyy') {
    return (
      <InputMask
        mask="99.99.9999"
        aria-label={eingabefeldBeschreibung}
        onChange={handleAntwortChange('day')}
        value={datum}
      />
    );
  } else if (datumsformat === 'mm-yyyy') {
    return (
      <InputMask
        mask="99.9999"
        aria-label={eingabefeldBeschreibung}
        onChange={handleAntwortChange('month')}
        value={datum}
      />
    );
  } else {
    return (
      <InputMask
        aria-label={eingabefeldBeschreibung}
        mask="9999"
        onChange={handleAntwortChange('year')}
        value={datum}
      />
    );
  }
}

const DateAnswer = ({
  value = '',
  symbol,
  datumsformat,
  handleElementChange,
  eingabefeldBeschreibung,
  minJahr,
  maxJahr,
}) => {
  const [antwortWert, setAntwortWert] = useState(value);
  const [isValid, setIsValid] = useState(true);
  const [untereGrenzeEingehalten, setUntereGrenzeEingehalten] = useState(true);
  const [obereGrenzeEingehalten, setObereGrenzeEingehalten] = useState(true);

  const checkIfInvalid = (input, type, minJahr, maxJahr) => {
    let inputdate = input.split('.');
    let minJahrDate;
    let maxJahrDate;
    let validityObj = {
      untereGrenzeEingehalten: true,
      obereGrenzeEingehalten: true,
      validesDatum: false,
    };
    if (minJahr) {
      try {
        minJahrDate = DateTime.fromObject({ year: minJahr });
      } catch (e) {
        console.log('minJahr konnte nicht gelesen werden. Es wird ignoriert.');
      }
    }
    if (maxJahr) {
      try {
        maxJahrDate = DateTime.fromObject({
          month: 12,
          day: 31,
          year: maxJahr,
        });
      } catch (e) {
        console.log('maxJahr konnte nicht gelesen werden. Es wird ignoriert.');
      }
    }

    let testDate;
    if (type === 'year') {
      try {
        testDate = DateTime.fromObject({ year: inputdate[0] });
      } catch (e) {
        validityObj['validesDatum'] = false;
        return validityObj;
      }
    } else if (type === 'month') {
      try {
        testDate = DateTime.fromObject({
          year: inputdate[1],
          month: inputdate[0],
        });
      } catch (e) {
        validityObj['validesDatum'] = false;
        return validityObj;
      }
    } else if (type === 'day') {
      try {
        testDate = DateTime.fromObject({
          month: inputdate[1],
          year: inputdate[2],
          day: inputdate[0],
        });
      } catch (e) {
        validityObj['validesDatum'] = false;
        return validityObj;
      }
    }
    if (testDate) {
      //testen ob die Grenzen eingehalten wurden
      if (minJahrDate && minJahrDate > testDate) {
        validityObj['untereGrenzeEingehalten'] = false;
      }
      if (maxJahrDate && maxJahrDate < testDate) {
        validityObj['obereGrenzeEingehalten'] = false;
      }
      validityObj['validesDatum'] = testDate.isValid;
    }
    return validityObj;
  };

  //Speichere Antwort in dem vorgegeben Format
  const handleAntwortChange = (dateFormat) => {
    return (event) => {
      if (
        event.target.value === '____' ||
        event.target.value === '__.____' ||
        event.target.value === '__.__.____' ||
        event.target.value === ''
      ) {
        setIsValid(true);
        setObereGrenzeEingehalten(true);
        setUntereGrenzeEingehalten(true);
      } else {
        const validityObj = checkIfInvalid(
          event.target.value,
          dateFormat,
          minJahr,
          maxJahr
        );
        setIsValid(validityObj['validesDatum']);
        setUntereGrenzeEingehalten(validityObj['untereGrenzeEingehalten']);
        setObereGrenzeEingehalten(validityObj['obereGrenzeEingehalten']);
      }
      setAntwortWert(event.target.value);
      handleElementChange(event.target.value);
    };
  };

  return (
    <div>
      {eingabefeldBeschreibung && <p>{eingabefeldBeschreibung}</p>}
      <div
        className={styles.date_input_container}
        css={css`
          ${(!isValid || !untereGrenzeEingehalten || !obereGrenzeEingehalten) &&
          'border: 2px solid red;'}
        `}
      >
        <div className={styles.date_icon_container}>
          <img src={iconPath} alt={altText} />
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
            padding-left: 30px;
          `}
        >
          {renderInputByRequestedDateFormat(
            datumsformat,
            antwortWert,
            handleAntwortChange,
            eingabefeldBeschreibung
          )}
        </div>
      </div>
      {!untereGrenzeEingehalten && (
        <p aria-live="polite">
          Das angebene Datum muss aus dem Jahr {minJahr} oder später sein.
        </p>
      )}
      {!obereGrenzeEingehalten && (
        <p aria-live="polite">
          Das angebene Datum darf maximal im Jahr {maxJahr} sein.
        </p>
      )}
    </div>
  );
};
export default DateAnswer;
