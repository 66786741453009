/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React from 'react'; // eslint-disable-line

import { breakpoints } from '../../styles/breakpoints';

const RechteSeite = (props) => (
  <div
    css={css`
      display: grid;
      /* [weißer space links] [content] [weißer space rechts]  */
      grid-template-columns: minmax(100px, auto) auto minmax(100px, auto);
      /* [weißer space oben] [content] [weißer space unten]  */
      grid-template-rows: 100px auto 100px;

      @media (${breakpoints.tablet}) {
        grid-template-columns: 60px auto 60px;
        grid-template-rows: 10px auto 100px;
      }

      @media (${breakpoints.smartphone}) {
        /* nur noch sehr schmaler Rand bei mobile view */
        grid-template-columns: 20px auto 20px;
      }
    `}
    id="fragen-start"
  >
    <div
      css={css`
        grid-row: 1/2;
      `}
    ></div>
    <div
      css={css`
        grid-column: 2/3;
        grid-row: 2/3;
      `}
    >
      {props.children}
    </div>
    <div
      css={css`
        grid-row: 3/3;
      `}
    ></div>
  </div>
);

export default RechteSeite;
