import axios from 'axios';
import { API_HOST, defaultAuth, ENDPOINTS } from "./api"

export function uploadDateiFuerFrage(file, id, frageMetadaten, dateiEndung, erlaubeAlleFormate, uuid) {
  const url = getEndpunkt(frageMetadaten);
  const formData = new FormData();
  formData.append('datei', file);
  formData.append('beantworteteTeilFrage', id);
  formData.append('dateiEndung', dateiEndung);
  formData.append('erlaubeAlleFormate', String(erlaubeAlleFormate || false));
  formData.append('uuid', uuid);
  return axios({
    url,
    method: 'post',
    data: formData,
  });
}

export function loescheDateiFuerFrage(antwortId, frageMetadaten, hochgeladeneDatei) {
  const url = getEndpunkt(frageMetadaten) + "/" + antwortId + "?uuid=" + hochgeladeneDatei.uuid;
  return axios({
    url,
    method: 'delete',
  })
}

function getEndpunkt(frageMetadaten) {
  const { ausschussNr, gemeindeNr, regNr, pruefCode, frageId } = frageMetadaten;
  const url = `${API_HOST}${ENDPOINTS.ERGEBNISSE}/${ausschussNr}/${gemeindeNr}/${regNr}/${pruefCode}/${frageId}`;
  return url;
}
