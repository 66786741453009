import axios from 'axios';
import { API_HOST, ENDPOINTS } from './api';

export function fetchKonfiguration() {
  const url = `${API_HOST}${ENDPOINTS.KONFIGURATION}/`;

  return axios({
    url,
    method: 'get',
  });
}

export function fetchFragen12(ausschuss, gemeinde, pruefCode, regNummer) {
  const url = `${API_HOST}${ENDPOINTS.FRAGEN12}/${ausschuss}/${gemeinde}/${regNummer}/${pruefCode}`;

  return axios({
    url,
    method: 'get',
  });
}

export function fetchFragen3(ausschuss, gemeinde, pruefCode, regNummer) {
  const url = `${API_HOST}${ENDPOINTS.FRAGEN3}/${ausschuss}/${gemeinde}/${regNummer}/${pruefCode}`;

  return axios({
    url,
    method: 'get',
  });
}

export function fetchAusschuesse(ausschussKennziffer) {
  const url = `${API_HOST}${ENDPOINTS.AUSSCHUESSE}/${
    ausschussKennziffer || ausschussKennziffer === 0 ? ausschussKennziffer : ''
  }`;

  return axios({
    url,
    method: 'get',
  });
}
export function fetchAusschuesseDetails() {
  const url = `${API_HOST}${ENDPOINTS.AUSSCHUESSEDETAILS}`;
  return axios({
    url,
    method: 'get',
  });
}

export function fetchGemeinden() {
  const url = `${API_HOST}${ENDPOINTS.GEMEINDEN}`;

  return axios({
    url,
    method: 'get',
  });
}

/*
Seitendaten sind z.b. der Content für das Impressum, die Datenschutzerklärung, und die Ansprechpartnerseite
*/
export function fetchSeitenDaten(seitenName) {
  const url = `${API_HOST}${ENDPOINTS.SEITENDATEN}/${seitenName}`;
  return axios({
    url,
    method: 'get',
  });
}

/*function uploadFileForQuestion(file, id, antwort) {
  const { ausschussNr, gemeindeNr, regNr, pruefCode, frageId } = antwort;
  const url = `${API_HOST}${ENDPOINTS.ERGEBNISSE}/${ausschussNr}/${gemeindeNr}/${regNr}/${pruefCode}/${frageId}`;
  const formData = new FormData();
  formData.append('datei', file);
  formData.append('beantworteteFrage', id);
  return axios({
    url,
    method: 'post',
    data: formData,
    auth: {
      username: 'zeonline',
      password: 'enpitPBZEo1',
    },
  });
}*/
