export const getGenitiv = (s) => {
  return s.replaceAll('haus', 'hauses').replaceAll('Haus', 'Hauses');
};

export const setGenitiv = (text, gebaeudeArt) => {
  if (gebaeudeArt && text) {
    const textMitGenitiv = text
      //"haus" für "Einfamilienhaus" etc.
      //"Haus" für den Fallback "Haus"
      .replaceAll('[Gebäudeart;Genitiv]', getGenitiv(gebaeudeArt))
      .replaceAll('[Gebäudeart]', gebaeudeArt);
    return textMitGenitiv;
  } else {
    return text;
  }
};
