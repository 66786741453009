/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { useState } from 'react'; // eslint-disable-line
import styles from './Answer.module.css';

const Textfeld = ({
  value = '',
  placeholder,
  ariaLabelledby = null,
  ariaLabel = null,
  required = false,
  handleElementChange,
  type = 'text',
}) => {
  const [text, setText] = useState(value);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setText(value);
    handleElementChange(value);
  };

  return (
    <input
      className={styles.textfeld}
      value={text}
      placeholder={placeholder}
      aria-labelledby={ariaLabelledby}
      aria-label={ariaLabel}
      required={required}
      type={type}
      onChange={(e) => handleInputChange(e)}
    />
  );
};

export default Textfeld;
