/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

import React, { useEffect, useState, useRef } from 'react';

import { useLocation } from 'react-router-dom';

import RightSide from '../components/Layout/RightSide';
import LeftSide from '../components/Layout/LeftSide';
import TwoColumsBox from '../components/Layout/TwoColumsBox';

import Button from '../components/Button';
import Textfeld from '../components/AntwortElemente/Textfeld';
import Datalist from '../components/Datalist';

import colors from '../styles/colors.js';
import { breakpoints } from '../styles/breakpoints';

import useLocalStorage from '../hooks/useLocalStorage';

import { fetchSeitenDaten } from '../service/fetchConfig';
import { ENDPOINTS } from '../service/api';

import { fetchAusschuesse, fetchGemeinden } from '../service/fetchConfig';

export default function Start() {
  //Get Parameter
  const params = new URLSearchParams(useLocation().search);

  const [ausschuesse, setAusschuesse] = useState(null);
  const [gemeinden, setGemeinden] = useState(null);
  const [gemeindenListe, setGemeindenListe] = useState(null); //für Datalist

  //States
  const [ausschuss, setAusschuss] = useLocalStorage(
    'ausschuss',
    params.get('ausschuss')
  );
  const [gemeinde, setGemeinde] = useLocalStorage(
    'gemeinde',
    params.get('gemeinde')
  );
  const [regNummer, setRegNummer] = useLocalStorage(
    'regnr',
    params.get('regnummer')
  );
  const [code, setCode] = useLocalStorage('pruefcode', params.get('code'));

  const [localStorageAblaufzeit, setLocalStorageAblaufzeit] = useLocalStorage(
    'ablaufzeit',
    params.get('ablaufzeit')
  );

  const [seitenDaten, setSeitenDaten] = useState();

  const formEl = useRef(null);

  //LocalStorage nach X tagen löschen
  useEffect(() => {
    const heute = new Date();
    const ablaufzeitInTagen = 7;
    if (localStorageAblaufzeit) {
      //wurde das Ablaufdatum erreicht?
      if (localStorageAblaufzeit < heute.getTime()) {
        window.localStorage.clear();
      }
    } else {
      //Datum setzen falls keins vorhanden
      setLocalStorageAblaufzeit(
        heute.setDate(heute.getDate() + ablaufzeitInTagen)
      );
    }
  }, []);

  //Seiten Daten abrufen
  useEffect(() => {
    const ladeSeitenDaten = async () => {
      const seitenDaten = (await fetchSeitenDaten(ENDPOINTS.STARTSEITE)).data;
      setSeitenDaten(seitenDaten);
    };

    ladeSeitenDaten();
  }, []);

  //GAA+Gemeinde Liste vom Server holen
  useEffect(() => {
    const ladeKonfigurationen = async () => {
      if (!gemeinden) {
        //Gemeinden fetchen
        try {
          const gemeindenJSON = (await fetchGemeinden()).data;
          setGemeinden(gemeindenJSON);
        } catch (e) {
          console.error('Fehler beim Laden der Gemeinden: ' + e.message);
        }

        //Ausschuesse fetchen
        try {
          const ausschuesseJSON = (await fetchAusschuesse()).data;

          //Alphabetische Sortierung Ausschüsse
          ausschuesseJSON.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });

          setAusschuesse(
            ausschuesseJSON.filter((ausschuss) => ausschuss.kennziffer !== '0')
          );
        } catch (e) {
          console.error(
            'Fehler beim Laden der Gutachterausschüsse: ' + e.message
          );
        }
      }
    };

    ladeKonfigurationen();
  }, []);

  //HandleFunctions
  const handleSelectAusschuss = (value) => setAusschuss(value);
  const handleSelectGemeinde = (value) => setGemeinde(value);
  const handleChangeRegNummer = (value) => setRegNummer(value.trim());
  const handleChangeCode = (value) => setCode(value.trim());

  //All gemeinden in one array to display in datalist
  useEffect(() => {
    if (gemeinden) {
      const alleGemeinden = (() => {
        let arrayOfAllGemeinden = [];
        for (let ausschussKennziffer in gemeinden) {
          arrayOfAllGemeinden.push(...gemeinden[ausschussKennziffer]);
        }
        return arrayOfAllGemeinden;
      })();

      //Alphabetische Sortierung
      alleGemeinden.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      setGemeindenListe(alleGemeinden);
    }
  }, [gemeinden]);

  const getAusschuss = (gemeindeKennzeichen) => {
    for (let ausschussKennziffer in gemeinden) {
      //filter for gemeindeKennziffer to get gemeinde
      let gemeinde = gemeinden[ausschussKennziffer].filter(
        (gemeinde) => gemeinde.kennzeichen === gemeindeKennzeichen
      );

      //if gemeinde is found return the related ausschuss
      if (gemeinde.length !== 0) {
        //filter ausschusse by kennziffer
        let ausschuss = ausschuesse.filter(
          (ausschuss) => ausschuss.kennziffer === ausschussKennziffer
        );
        return ausschuss;
      }
    }
    return null;
  };

  const handleClickOnStart = (e) => {
    if (!formEl.current.reportValidity()) {
      e.preventDefault();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const buildQuery = () => {
    return `ausschuss=${ausschuss}&gemeinde=${gemeinde}&regnummer=${regNummer}&code=${code}`;
  };

  const LadeDaten = () => {
    return (
      <div
        css={css`
          border: 1px solid ${colors.blue};
          border-radius: 5px;
          padding: 15px;
        `}
      >
        Lade Daten vom Server
      </div>
    );
  };

  return (
    <TwoColumsBox>
      <LeftSide>
        {seitenDaten && (
          <div dangerouslySetInnerHTML={{ __html: seitenDaten.text1 }} />
        )}
        <div
          css={css`
            padding: 30px 0px;
          `}
        ></div>
      </LeftSide>
      <RightSide>
        <div
          css={css`
            border: 2px solid ${colors.blue};
            border-radius: 5px;
            padding: 37px 46px 46px 37px;

            @media (${breakpoints.tablet}) {
              padding: 37px 0px 36px 0px;
              border: none; /* keinen Rahmen in Mobile, wurde so besprochen */
            }

            @media (${breakpoints.smartphone}) {
              padding: 20px 46px 46px 20px;
              border: none;
            }
          `}
        >
          <form
            onSubmit={(e) => handleSubmit(e)}
            ref={formEl}
            css={css`
              /* Grid für alle 4 Elemente (Text+Input), 
               die ersten zwei Elemente sind immer auf voller Breite (2 Columns), 
               die letzten Beiden teilen sich auf Desktop auf jeweils 1 Column auf */
              display: grid;
              grid-template-columns: 2fr 1.5fr;
              grid-template-rows: repeat(auto-fit, auto);
              grid-gap: 40px 30px;
              grid-template-areas:
                'ausschuss ausschuss'
                'gemeinde gemeinde'
                'regnummer code'
                'regnummer code'
                'button button';

              @media (max-width: 950px) {
                /* Hinweis: Im Design sollte eigentlich erst ab Mobile Registrierungsnummer und Prüfcode untereinander stehen*/
                grid-template-areas:
                  'ausschuss ausschuss'
                  'gemeinde gemeinde'
                  'regnummer regnummer'
                  'code code'
                  'button button';
              }
            `}
          >
            <div
              css={css`
                grid-area: ausschuss;
              `}
            >
              <h2 id="headline-ausschuss">
                Wählen Sie Ihren Gutachterausschuss für Grundstückswerte aus
              </h2>
              <div
                css={css`
                  word-wrap: break-word;
                  hyphens: auto;
                `}
              >
                Geben Sie dazu den Namen Ihres zuständigen Ausschusses in die
                Maske ein.
              </div>
              {ausschuesse ? (
                <Datalist
                  key={'Ausschussliste'}
                  value={ausschuss}
                  name="ausschuss"
                  options={gemeinde ? getAusschuss(gemeinde) : ausschuesse}
                  placeholder="z.B. Bundesstadt Bonn"
                  onSelect={handleSelectAusschuss}
                  ariaLabelledby="headline-ausschuss"
                  required={true}
                />
              ) : (
                <LadeDaten />
              )}
            </div>
            <div
              css={css`
                grid-area: gemeinde;
              `}
            >
              <h2 id="headline-gemeinde">
                Wählen Sie die Stadt oder Gemeinde, in welcher die Immobilie
                liegt
              </h2>
              {gemeindenListe && gemeinden ? (
                <Datalist
                  key={'Gemeindenliste' + gemeindenListe.length}
                  value={gemeinde}
                  name="gemeinde"
                  options={ausschuss ? gemeinden[ausschuss] : gemeindenListe}
                  placeholder="z.B. Bonn"
                  onSelect={handleSelectGemeinde}
                  ariaLabelledby="headline-gemeinde"
                  required={true}
                />
              ) : (
                <LadeDaten />
              )}
            </div>

            {/* Desktop: linke Seite */}
            <div
              css={css`
                grid-area: regnummer;
              `}
            >
              <h2
                id="headline-registrierungsnummer"
                css={css`
                  hyphens: auto;
                `}
              >
                Registrierungsnummer des Kauffalles
              </h2>

              <Textfeld
                value={regNummer ? regNummer : ''}
                placeholder="z.B. 245-213-5512-12"
                ariaLabelledby="headline-registrierungsnummer"
                handleElementChange={handleChangeRegNummer}
                required={true}
              />
            </div>

            {/* Desktop: rechte Seite */}
            <div
              css={css`
                grid-area: code;
                align-self: end;
              `}
            >
              <h2 id="headline-pruefcode">Prüfcode</h2>
              <Textfeld
                value={code ? code : ''}
                placeholder="z.B. 245-213-5512-12"
                ariaLabelledby="headline-pruefcode"
                handleElementChange={handleChangeCode}
                required={true}
              />
            </div>

            <div
              css={css`
                grid-area: button;
                justify-self: end;
              `}
            >
              <Button
                link={`/frage/01-01-01?${buildQuery()}`}
                text="Mit dem Fragebogen starten"
                handleClick={handleClickOnStart}
              />
            </div>
          </form>
        </div>
      </RightSide>
    </TwoColumsBox>
  );
}
