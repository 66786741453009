/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

import React from 'react';

import { useLocation } from 'react-router-dom';

import { pdfErgebnissUrl } from '../../service/ergebnisseAPI';

import Button from '../Button';

export default function Zusammenfassung({ seite }) {
  const apiHost = process.env.REACT_APP_API_HOST;

  const query = useLocation().search;
  const params = new URLSearchParams(query);

  const ausschuss = params.get('ausschuss');
  const gemeinde = params.get('gemeinde');
  const pruefCode = params.get('code');
  const regNummer = params.get('regnummer');

  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: 1.5fr 1fr;
        grid-template-rows: auto;
        grid-gap: 30px 50px;
        grid-template-areas:
          'downloadhead downloadhead'
          'downloadtext button'
          'htmlBlock htmlBlock';
        @media (max-width: 800px) {
          grid-template-columns: 1fr;
          grid-template-areas:
            'downloadhead'
            'downloadtext'
            'button'
            'htmlBlock';
        }
      `}
    >
      {/* PDF - DOWNLOAD */}
      <div
        css={css`
          grid-area: downloadhead;
          padding-top: 20px;
        `}
        dangerouslySetInnerHTML={{ __html: seite.herunterladenTitle }}
      />
      <div
        css={css`
          grid-area: downloadtext;
        `}
        dangerouslySetInnerHTML={{ __html: seite.herunterladenText }}
      />
      <div
        css={css`
          grid-area: button;
        `}
      >
        <Button
          link={`${pdfErgebnissUrl(ausschuss, gemeinde, pruefCode, regNummer)}`}
          text="PDF herunterladen"
          invert={true}
          absolute={true}
        />
      </div>

      {/* Absenden */}
      <div
        css={css`
          grid-area: htmlBlock;
          padding-top: 20px;
        `}
        dangerouslySetInnerHTML={{ __html: seite.htmlBlock }}
      />
    </div>
  );
}
