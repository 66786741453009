/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React from 'react';
import { breakpoints } from '../../styles/breakpoints';
import colors from '../../styles/colors.js';

export default function TwoColumsBox(props) {
  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: 3.3fr 7fr;
        grid-template-rows: 1fr;
        height: 100%;
        border-top: 4px solid ${colors.blue};
        @media (${breakpoints.tablet}) {
          grid-template-columns: 1fr;
          grid-template-rows: 0.3fr auto;
        }
      `}
    >
      {props.children}
    </div>
  );
}
