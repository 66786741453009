/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import Checkbox from '../AntwortElemente/Checkbox';
import Textbereich from '../AntwortElemente/Textbereich';
import Tooltip from '../Tooltip';

import React, { useState, useEffect } from 'react';
import BeschreibungMitTooltip from '../AntwortElemente/BeschreibungMitTooltip';

import useDebouncedValue from '../../hooks/useDebouncedValue';

export default function Mehrfachauswahl({
  antwortOptionen,
  frageId,
  beschreibung,
  returnAntwortOnChange,
  bisherigeAuswahlen = [],
  bisherigeEingaben = {},
  bilderPfad,
  tooltipText,
  gebaeudeArt,
}) {
  const [auswahlen, setAuswahlen] = useState(bisherigeAuswahlen);
  const [eingaben, setEingaben] = useState(bisherigeEingaben);

  const debouncedEingabe = useDebouncedValue(eingaben, 400);

  //Eingaben werden vorübergehend in einem Objekt gespeichert
  const handleChangeEingabe = (eingabe, antwortWert) => {
    setEingaben((prev) => {
      prev[antwortWert] = eingabe;
      return prev;
    });
  };

  const handleChangeAuswahl = (antwortWert, isChecked) => {
    //filtern nach werten die angewählt wurden (=true sind)
    setAuswahlen((prev) => {
      if (isChecked) {
        return [...prev, antwortWert];
      } else {
        return prev.filter((e) => e !== antwortWert);
      }
    });
  };

  const getIsChecked = (antwortOptionWert) => {
    return auswahlen && Array.isArray(auswahlen)
      ? auswahlen.includes(antwortOptionWert)
      : false;
  };
  useEffect(() => {
    returnAntwortOnChange({ auswahlen: auswahlen, eingaben: eingaben });
  }, [auswahlen, eingaben]);

  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(auto-fit, auto);
        grid-gap: 0px 38px;
      `}
    >
      <BeschreibungMitTooltip
        beschreibung={beschreibung}
        tooltipText={tooltipText}
        tooltipID={frageId}
        gebaeudeArt={gebaeudeArt}
      />
      <div role="group">
        {antwortOptionen
          ? antwortOptionen.map((antwortOption, index) => (
              <div key={frageId + antwortOption.wert}>
                <Checkbox
                  antwortText={antwortOption.text}
                  frageId={frageId}
                  antwortWert={antwortOption.wert}
                  checked={getIsChecked(antwortOption.wert)}
                  index={index}
                  tooltipText={antwortOption.tooltip}
                  grafik={
                    antwortOption.grafik
                      ? `${bilderPfad}${antwortOption.grafik}`
                      : null
                  }
                  handleElementChange={handleChangeAuswahl}
                />
                {antwortOption.eingabefeld === 'ja' &&
                getIsChecked(antwortOption.wert) ? (
                  <Textbereich
                    antwortWert={antwortOption.wert}
                    value={eingaben[antwortOption.wert]}
                    index={index}
                    label={antwortOption.text}
                    handleElementChange={(eingabe) =>
                      handleChangeEingabe(eingabe, antwortOption.wert)
                    }
                    rows={3}
                    background={'#f4f4f4'}
                  />
                ) : null}
              </div>
            ))
          : null}
      </div>
    </div>
  );
}
