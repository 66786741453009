import axios from 'axios';
import { API_HOST, defaultAuth, ENDPOINTS } from './api';

export function sendErgebnisse(
  ausschuss,
  gemeinde,
  pruefCode,
  regNummer,
  ergebnis,
  abgabe = false
) {
  const url = `${API_HOST}${ENDPOINTS.ERGEBNISSE}/${ausschuss}/${gemeinde}/${regNummer}/${pruefCode}`;
  const urlParams = { abgabe: abgabe };
  //Schicke url parameter mit, wenn das Ergebnis abgeben, werden soll.
  if (abgabe) {
    return axios({
      url,
      method: 'post',
      data: ergebnis,
      params: urlParams,
    });
  } else {
    return axios({
      url,
      method: 'post',
      data: ergebnis,
    });
  }
}

export function fetchErgebnisse(ausschuss, gemeinde, pruefCode, regNummer) {
  const url = `${API_HOST}${ENDPOINTS.ERGEBNISSE}/${ausschuss}/${gemeinde}/${regNummer}/${pruefCode}/json`;

  return axios({
    url,
    method: 'get',
  });
}

export function pdfErgebnissUrl(ausschuss, gemeinde, pruefCode, regNummer) {
  const url = `${API_HOST}${ENDPOINTS.ERGEBNISSE}/${ausschuss}/${gemeinde}/${regNummer}/${pruefCode}/pdf`;

  return url;
}
