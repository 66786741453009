import styles from './Answer.module.css';

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { useState } from 'react';

const Textbereich = ({
  antwortWert,
  value,
  index,
  label = 'Geben Sie bitte Ihre Antwort ein',
  rows = 3,
  handleElementChange,
  disabled = false,
  background = '#fff',
  placeholder = '',
}) => {
  const [text, setText] = useState(value);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setText(value);
    handleElementChange(value);
  };

  return (
    <div
      css={css`
        background-color: ${background};
        flex-direction: column;
        justify-content: space-around;
        padding-top: 20px;
        margin-bottom: 9px;
        z-index: 0;
      `}
    >
      <label
        id={'label-tb-' + index}
        className={styles.label}
        htmlFor={'tb-' + index}
        css={css`
          font-size: 16px;
          margin-left: 5px;
        `}
      >
        {label}
        <textarea
          type="textarea"
          id={'tb-' + index}
          name={antwortWert}
          disabled={disabled}
          placeholder={placeholder}
          className={(styles.container, styles.label)}
          aria-labelledby={'label-tb-' + index}
          value={text}
          rows={rows}
          onChange={(e) => handleInputChange(e)}
          maxLength={1500}
          css={css`
            width: 100%;
            margin-top: 20px;
            padding: 12px 20px;
            background-color: #ffffff;
            border: 1px solid #003064;

            border-radius: 2px;
            box-sizing: border-box;
            resize: none;

            ::placeholder {
              color: #acacac;
              font-size: 16px;
              font-family: 'BentonSans-Regular', sans-serif;
            }
          `}
        />
      </label>
    </div>
  );
};

export default Textbereich;
