/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { css, jsx } from "@emotion/react"
import { breakpoints } from "../../styles/breakpoints"
import styles from "./Answer.module.css"
import QRCode from "qrcode.react"

const GeraeteWechsel = () => {
  const [qrCodeSichtbar, setQRCodeSichtbar] = React.useState(false);
  return (
    <div
      css={css`
          border: 1px solid #003064;
          padding: 15px;
          color: #003064;
          margin-bottom: 60px;
          @media (${breakpoints.smartphone}) {
            display: none;
          }
        `}
    >
      <p>
        Sie können zur Aufnahme von Fotos mittels eines QR-Codes auf ein
        Mobiles Gerät wechseln, ohne dass Daten verloren gehen.
      </p>
      <button
        className={styles.buttonStyle}
        tabIndex="0"
        onClick={() => {
          setQRCodeSichtbar(!qrCodeSichtbar);
        }}
      >
        {qrCodeSichtbar ? 'QR-Code ausblenden' : 'QR-Code anzeigen'}
      </button>
      {qrCodeSichtbar && (
        <>
          <hr
            css={css`
                border-top: 1px solid #003064;
                margin-left: 40px;
                margin-right: 40px;
                margin-top: 20px;
                margin-bottom: 30px;
              `}
          />
          <h2>Auf das Handy wechseln (QR-Code)</h2>
          <p>
            Bitte mit dem Mobiltelefon Einscannen. Sie können die Bearbeitung
            dann lückenlos am Handy fortsetzen. Wenn Sie ihre Eingaben am
            Handy abgeschlossen haben, aktualisieren sie die Browser-Seite.
          </p>
          <div
            css={css`
                display: flex;
                justify-content: center;
              `}
          >
            <QRCode
              value={window.location.href}
              size={240}
              css={css`
                  margin-top: 20px;
                  padding: 10px;
                  border: 10px solid #003064;
                  border-radius: 9px;
                `}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default GeraeteWechsel;
