/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { breakpoints } from '../styles/breakpoints';
import React from 'react'; // eslint-disable-line

import colors from '../styles/colors.js';

import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

export const buttonStyle = (invert, disabled = false) => css`
  background-color: ${invert ? '#FFFFFF' : colors.blue};
  border-radius: 2px;
  border: 1px solid #000000;
  font-size: 14px;
  color: ${invert ? colors.blue : '#FFFFFF'};
  text-align: center;
  padding: 12px 37px;
  cursor: ${disabled ? 'not-allowed' : 'pointer'};
  opacity: ${disabled ? 0.5 : 1};

  @media (${breakpoints.tablet}) {
    padding: 12px 30px;
  }
  @media (${breakpoints.smartphone}) {
    padding: 12px 5px;
  }
`;

const button = ({
  link = '#',
  text,
  invert = false,
  absolute = false,
  disabled = false,
  handleClick = () => {},
}) =>
  disabled ? (
    <button
      disabled
      aria-disabled="true"
      css={buttonStyle(invert, disabled)}
      className={text.toLowerCase() + ' button'}
    >
      {text}
    </button>
  ) : absolute ? (
    <a
      css={buttonStyle(invert)}
      href={link}
      title={text}
      target="_blank"
      className={text.toLowerCase() + ' button'}
    >
      {text}
    </a>
  ) : (
    <HashLink
      to={link}
      css={buttonStyle(invert)}
      role="button"
      title={text}
      onClick={(e) => handleClick(e)}
      className={text.toLowerCase() + ' button'}
    >
      {text}
    </HashLink>
  );

export default button;
