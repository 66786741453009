import React, { Component } from 'react'; // eslint-disable-line
import globalColors from '../styles/colors';

const colors = {
  border: 'black',
  background: 'white',
  text: 'black',
  arrow: 'black',
  placeholder: globalColors.placeholder,
  listBackground: 'white',
  listItem: 'black',
  listItemArrow: globalColors.blue,
  listItemHover: 'white',
  listItemHoverBackground: globalColors.blue,
  listItemActive: globalColors.blue,
  listItemActiveBorder: globalColors.blue,
  listEmpty: globalColors.gray,
};

class Datalist extends Component {
  constructor(props) {
    super(props);
    let value =
      props.value === '' || props.value === 0 || props.value
        ? props.value
        : false;
    this.state = {
      value,
      selected: value,
      options: props.options || [],
      optionsValues: props.options
        ? props.options.map((option) => {
            return option.value || option.kennzeichen || option.kennziffer;
          })
        : [],
      optionsLabels: props.options
        ? props.options.map((option) => {
            return option.name;
          })
        : [],
      optionsVisible: [],
      placeholder: props.placeholder || 'Search',
      notFoundText: props.notFoundText || 'No result found',
      focused: false,
      arrowPosition: -1,
      noInput: props.noInput || false,
      arrow: props.arrow || false,
      listMaxHeight: props.listMaxHeight || 140,
      multiple: props.multiple || false,
      disabled: props.disabled || false,
      required: props.required || false,
    };
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.select = this.select.bind(this);
    this.keyDown = this.keyDown.bind(this);
  }

  componentDidMount() {
    let component = this.root,
      findParent = (e) => {
        if (!e || !e.parentNode) {
          return false;
        }
        if (e === component) {
          return true;
        }
        return findParent(e.parentNode);
      };

    this.documentClick = (e) => {
      if (this.state.focused) {
        if (!findParent(e.target)) this.onBlur();
      }
    };
    document.addEventListener('click', this.documentClick);
    this.findInitialValue();
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.documentClick);
  }

  findInitialValue() {
    let { value, options } = this.state,
      match = false;
    options.forEach((item) => {
      if (!match)
        match =
          item.kennziffer === value || item.kennzeichen === value
            ? item
            : false;
    });
    this.setState({
      value: match ? match.name : '',
      selected: match ? match.name : '',
    });
  }

  static getDerivedStateFromProps(props, state) {
    return {
      options: props.options || [],
      optionsValues: props.options
        ? props.options.map((option) => {
            return option.value || option.kennzeichen || option.kennziffer;
          })
        : [],
      optionsLabels: props.options
        ? props.options.map((option) => {
            return option.name;
          })
        : [],
      placeholder: props.placeholder || 'Search',
      notFoundText: props.notFoundText || 'No result found',
      disabled: props.disabled || false,
      focused: props.disabled && state.focused ? false : state.focused,
      arrow: props.arrow || false,
    };
  }

  sort() {
    let { value, optionsVisible } = this.state,
      firsts = [],
      seconds = [];
    if (value) {
      optionsVisible = optionsVisible.sort((a, b) => {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
      seconds = optionsVisible.filter((item) => {
        return item.toLowerCase().indexOf(value.toLowerCase()) === 0;
      });
      firsts = seconds.filter((item) => {
        return item.indexOf(value) === 0;
      });
      seconds = seconds.filter((item) => {
        return item.indexOf(value) !== 0;
      });
      optionsVisible = optionsVisible.filter((item) => {
        return item.toLowerCase().indexOf(value.toLowerCase()) !== 0;
      });
      optionsVisible = [...firsts, ...seconds, ...optionsVisible];
    }
    this.setState({
      optionsVisible,
    });
  }

  findAssumption() {
    let { optionsVisible, value } = this.state,
      assume = optionsVisible.find((item) => {
        return item.indexOf(value) === 0;
      }),
      assumeLower = optionsVisible.find((item) => {
        return item.toLowerCase().indexOf(value.toLowerCase()) === 0;
      });
    if (!assume && !assumeLower) return;
    this.setState({
      assume: value ? (assume ? assume : assumeLower) : false,
    });
  }

  buildList(value) {
    let { optionsLabels } = this.state,
      optionsVisible = optionsLabels.filter((item) => {
        return item.toLowerCase().indexOf(value.toLowerCase()) >= 0;
      });
    this.setState(
      {
        value,
        optionsVisible,
        focused: true,
        assume: false,
        arrowPosition: -1,
      },
      () => {
        this.sort();
        this.findAssumption();
      }
    );
  }

  onChange(e) {
    let value = e.target.value;
    if (!this.select(value)) this.buildList(value);
  }

  keyDown(e) {
    let { assume = '', arrowPosition, optionsVisible, value } = this.state;

    if ((e.keyCode === 9 || e.keyCode === 13) && assume) {
      e.preventDefault();
      this.select(assume);
    }

    //ARROW DOWN
    if (e.keyCode === 40) {
      e.preventDefault();
      if (arrowPosition < optionsVisible.length - 1) {
        arrowPosition++;
      } else {
        arrowPosition = 0;
      }
      let assume = optionsVisible[arrowPosition];

      if (assume === undefined) {
        //first time, no value choosed
        this.onFocus();
      } else {
        value = assume.slice(0, value.length);

        this.setState(
          {
            focused: true,
            value,
            arrowPosition,
            assume,
          },
          this.scrollList
        );
      }
    }

    if (e.keyCode === 38) {
      e.preventDefault();
      if (arrowPosition <= 0) {
        arrowPosition = optionsVisible.length - 1;
      } else {
        arrowPosition--;
      }
      let assume = optionsVisible[arrowPosition];

      if (assume === undefined) {
        //first time, no value choosed
        this.onFocus();
      } else {
        value = assume.slice(0, value.length);
        this.setState(
          {
            value,
            arrowPosition,
            assume,
          },
          this.scrollList
        );
      }
    }
  }

  scrollList() {
    let { list } = this,
      { arrowPosition } = this.state,
      target = '.searchable-list-item__' + arrowPosition;
    if (list) {
      let item = list.querySelector(target);
      item && item.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  onFocus() {
    let { optionsLabels, optionsVisible } = this.state;
    this.input && this.input.focus();
    this.setState({
      focused: true,
      optionsVisible: optionsVisible.length ? optionsVisible : optionsLabels,
    });
  }

  onBlur() {
    let { value, optionsLabels } = this.state,
      match = false;
    optionsLabels.forEach((item) => {
      if (!match)
        match = item.toLowerCase() === value.toLowerCase() ? item : false;
    });
    this.setState({
      focused: false,
      optionsVisible: [],
      value: match ? match : '',
      assume: false,
      arrowPosition: -1,
    });
  }

  select(value) {
    let { optionsLabels, options, selected } = this.state,
      newSelected =
        optionsLabels.find((item) => {
          return item === value;
        }) || '',
      newSelectedLower =
        optionsLabels.find((item) => {
          return item.toLowerCase() === value.toLowerCase();
        }) || '';
    newSelected = newSelected ? newSelected : newSelectedLower;
    this.setState(
      {
        selected: newSelected,
        value: newSelected ? newSelected : value,
        optionsVisible: [],
        focused: false,
        arrowPosition: -1,
        assume: false,
      },
      () => {
        selected !== newSelected &&
          this.props.onSelect &&
          this.props.onSelect(() => {
            let option = options.find((item) => {
              return item.name === newSelected;
            });

            return (
              option?.value ||
              option?.kennzeichen ||
              option?.kennziffer ||
              false
            );
          });
      }
    );
    return newSelected;
  }

  render() {
    let {
      value,
      optionsVisible,
      focused,
      placeholder,
      notFoundText,
      assume,
      arrowPosition,
      selected,
      noInput,
      arrow,
      listMaxHeight,
      required,
      disabled,
    } = this.state;
    return (
      <div
        className={[
          'searchable',
          focused ? 'searchable__active' : '',
          disabled ? 'searchable__disabled' : '',
        ].join(' ')}
        ref={(node) => (this.root = node)}
      >
        <div
          className={[
            'searchable-input',
            focused ? 'searchable-input__active' : '',
          ].join(' ')}
        >
          <div
            className="searchable-input-control"
            onClick={(e) => {
              let action = !noInput
                ? this.onFocus
                : focused
                ? this.onBlur
                : this.onFocus;
              action();
            }}
            //close List, when tabbing out
            //onKeyDown={(e) => {
            //  if (e.keyCode === 9) this.onBlur();
            //}}
          >
            <input
              type="text"
              onChange={this.onChange}
              value={value}
              placeholder={!assume ? placeholder : ''}
              onKeyDown={this.keyDown}
              ref={(node) => (this.input = node)}
              readOnly={noInput}
              disabled={disabled}
              required={required}
              aria-labelledby={this.props.ariaLabelledby}
            />
            {assume && (
              <span className="searchable-input-control-assume">
                {assume.split('').map((char, index) => {
                  return (
                    <span
                      key={char + index}
                      className={[
                        'searchable-input-control-assume-char',
                        char === char.toUpperCase()
                          ? 'searchable-input-control-assume-char__upper'
                          : 'searchable-input-control-assume-char__lower',
                        index <= value.length - 1
                          ? 'searchable-input-control-assume-char__hidden'
                          : '',
                      ].join(' ')}
                    >
                      {index <= value.length - 1 ? value[index] : char}
                    </span>
                  );
                })}
              </span>
            )}
          </div>
        </div>
        <div
          ref={(node) => (this.list = node)}
          className={[
            'searchable-list',
            focused ? 'searchable-list__visible' : '',
          ].join(' ')}
          style={{
            maxHeight: listMaxHeight,
          }}
        >
          {optionsVisible.length ? (
            optionsVisible.map((item, index) => {
              return (
                <div
                  className={[
                    'searchable-list-item',
                    'searchable-list-item__' + index,
                    item === selected ? 'searchable-list-item__active' : '',
                    arrowPosition >= 0 && index === arrowPosition
                      ? 'searchable-list-item__arrow-position'
                      : '',
                  ].join(' ')}
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.select(item);
                  }}
                >
                  {arrowPosition >= 0 && index === arrowPosition && (
                    <i className="searchable-list-item-arrow">
                      <svg viewBox="0 0 240.823 240.823">
                        <path
                          d="M183.189,111.816L74.892,3.555c-4.752-4.74-12.451-4.74-17.215,0c-4.752,4.74-4.752,12.439,0,17.179
		l99.707,99.671l-99.695,99.671c-4.752,4.74-4.752,12.439,0,17.191c4.752,4.74,12.463,4.74,17.215,0l108.297-108.261
		C187.881,124.315,187.881,116.495,183.189,111.816z"
                        />
                      </svg>
                    </i>
                  )}
                  {item}
                </div>
              );
            })
          ) : (
            <div className="searchable-list-empty">{notFoundText}</div>
          )}
        </div>

        <style>{`
                    .searchable {
                        position: relative;
                        z-index: 1;
                    }
                    .searchable__disabled {
                        opacity: 0.8;
                    }
                    .searchable__disabled, .searchable__disabled *, .searchable__disabled .searchable-input-arrow {
                        cursor: not-allowed;
                    }
                    .searchable__active {
                        z-index: 2;
                    }
                    .searchable, .searchable * {
                        box-sizing: border-box;
                    }
                    .searchable-input {
                        position: relative;
                        background-color: ${colors.background};
                        border-radius: 2px;
                        border-bottom: 1px solid transparent;
                        border: 1px solid ${colors.border};
                    }
                    .searchable-input input {
                        background-color: transparent;
                        border: none;
                        box-shadow: none;
                        font-size: 14px;
                        line-height: 1em;
                        padding: 14px;
                        width: 100%;
                        color: ${colors.text};
                        position: relative;
                    }
                    .searchable-input input::-ms-clear {
                        display: none;
                    }
                    .searchable-input input:read-only {
                        cursor: pointer;
                    }
                    .searchable-input input::placeholder {
                        color: ${colors.placeholder};
                    }
                    .searchable-input-arrow {
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 34px;
                        height: 100%;
                        cursor: pointer;
                    }
                    .searchable-input-arrow-inner {
                        width: 16px;
                        height: 16px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        transition: all 0.3s;
                        background-color: transparent;
                        border: none;
                        padding: 0;
                        margin: 0;
                        font-size: 0;
                        cursor: pointer;
                    }
                    .searchable-input-arrow-inner:focus {
                        outline: none;
                    }
                    .searchable-input-arrow svg {
                        fill: ${colors.arrow};
                    }
                    .searchable-input__active {
                        border-radius: 5px 5px 0 0;
                        border-bottom: 1px solid ${colors.listItemActiveBorder};
                    }
                    .searchable-input__active .searchable-input-arrow-inner {
                        transform: translate(-50%, -50%) rotate(-180deg);
                    }
                    .searchable-input-control-assume {
                        color: ${colors.border};
                        position: absolute;
                        left: 14px;
                        top: 50%;
                        font-size: 14px;
                        transform: translateY(-50%);
                    }
                    .searchable-input-control-assume-char__hidden {
                        color: transparent;
                    }
                    .searchable-input-control-assume-char__upper {
                        letter-spacing: -0.6px;
                    }
                    .searchable-list {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        width: 100%;
                        overflow: auto;
                        background-color: ${colors.listBackground};
                        padding: 10px 0;
                        display: none;
                        border-radius: 0 0 5px 5px;
                        font-size: 14px;
                    }
                    .searchable-list__visible {
                        display: block;
                    }
                    .searchable-list-empty {
                        color: ${colors.listEmpty};
                        text-align: center;
                        padding: 10px;
                        line-height: 1em;
                    }
                    .searchable-list-item {
                        color: ${colors.listItem};
                        padding: 10px 14px;
                        line-height: 1em;
                        cursor: pointer;
                        transition: all 0.3s;
                        position: relative;
                    }
                    .searchable-list-item-arrow {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 5px;
                        width: 10px;
                        height: 10px;
                        font-size: 0;
                    }
                    .searchable-list-item-arrow svg {
                        width: 100%;
                        height: auto;
                        fill: ${colors.listItemArrow};
                    }
                    .searchable-list-item:hover {
                        color: ${colors.listItemHover};
                        background-color: ${colors.listItemHoverBackground};
                    }
                    .searchable-list-item__active {
                        color: ${colors.listItemActive};
                    }
                    .searchable-list-item__arrow-position {
                        padding-left: 20px;
                    }
                    .searchable-list, .searchable-input {
                        transition: box-shadow 0.3s;
                    }
                    .searchable-list__visible, .searchable-input__visible, .searchable-list__active, .searchable-input__active {
                        box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.5);
                        outline: 4px solid #005bbd;
                    }

                `}</style>
      </div>
    );
  }
}

export default Datalist;
