import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './styles/styles.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import Layout from './components/Layout/Layout';

//Pages
import Start from './Seiten/Start';
import Ansprechpartner from './Seiten/Ansprechpartner';
import Datenschutz from './Seiten/Datenschutz';
import Impressum from './Seiten/Impressum';
import Frage from './Seiten/Frage';
import NichtGefunden from './Seiten/NichtGefunden';

function App() {
  return (
    <Router>
      <Layout>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL.
            <Route path="/impressum" component={impressum}>
            <About />
          </Route>*/}
        <Switch>
          <Route path="/" exact component={Start} />
          <Route path="/ansprechpartner" exact component={Ansprechpartner} />
          <Route path="/datenschutz" exact component={Datenschutz} />
          <Route path="/impressum" exact component={Impressum} />
          <Route path="/nichtgefunden" exact component={NichtGefunden} />
          <Route path="/frage/:id" component={Frage} />
        </Switch>
      </Layout>
    </Router>
  );
}

export default App;
