/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

import React, { useState } from 'react';

export default function Fehler({ beschreibung, seite }) {
  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-gap: 0px 38px;
      `}
    >
      <div
        css={css`
          padding-top: 40px;
        `}
      >
        <h2
          css={css`
            color: red;
          `}
        >
          {beschreibung}
        </h2>
      </div>
      <div
        css={css`
          padding-top: 20px;
        `}
        dangerouslySetInnerHTML={{ __html: seite.htmlBlock }}
      />
    </div>
  );
}
