/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

import React, { useState, useEffect } from 'react';

import Checkbox from '../AntwortElemente/Checkbox';

import Textfeld from '../AntwortElemente/Textfeld';
import TextBlock, {
  textAufbereitung,
  textMitAusschussDatenErsetzen,
} from '../TextBlock';
import { fetchAusschuesse } from '../../service/fetchConfig';
import { useLocation } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { breakpoints } from '../../styles/breakpoints';
import { fetchSeitenDaten } from '../../service/fetchConfig';
import { ENDPOINTS } from '../../service/api';
const Namensfeld = ({ handleChangeEingabe, value }) => (
  <Textfeld
    value={value}
    placeholder="Vor- und Nachname der einwilligenden Person"
    ariaLabel="Eingabefeld Vorname und Nachname"
    handleElementChange={handleChangeEingabe}
    required={false}
  />
);

export default function Einwilligung({
  returnAntwortOnChange,
  bisherigeAuswahlen = [],
  bisherigeEingaben = {},
  seite,
}) {
  //MediaQuery
  const smallScreen = useMediaQuery('(' + breakpoints.tablet + ')');

  //Es gibt nur 1 Checkbox
  const [auswahl, setAuswahl] = useState(
    bisherigeAuswahlen ? bisherigeAuswahlen[0] : null
  );
  //Es gibt nur 1 eingabefeld
  const [eingabe, setEingabe] = useState(
    bisherigeEingaben ? bisherigeEingaben['01'] : ''
  );

  //Checkbox wird angehakt
  const handleChangeAuswahl = (antwortWert, isChecked) => {
    if (isChecked) {
      setAuswahl(antwortWert);
    } else {
      setAuswahl(null);
    }
  };

  //Lade Ausschuss
  const [ausschuss, setAusschuss] = useState();
  const query = useLocation().search;
  const params = new URLSearchParams(query);
  useEffect(() => {
    const ladeAusschuss = async () => {
      const ausschussNummer = params.get('ausschuss');
      if (ausschussNummer) {
        const ausschussData = (await fetchAusschuesse(ausschussNummer)).data;
        setAusschuss(ausschussData);
      }
    };

    ladeAusschuss();
  }, []);

  //Eingaben werden vorübergehend in einem Objekt gespeichert
  const handleChangeEingabe = (eingabe) => {
    setEingabe(eingabe);
  };

  useEffect(() => {
    let eingaben = {};
    let auswahlen = [];
    eingaben['01'] = eingabe;
    if (auswahl) {
      auswahlen = [auswahl];
    }

    //wenn nicht beides ausgewählt, speichere eine leere antwort --> gehe zum Ende
    if (eingabe && eingabe !== '' && auswahl) {
      returnAntwortOnChange({ auswahlen: auswahlen, eingaben: eingaben });
    } else {
      returnAntwortOnChange({});
    }
  }, [auswahl, eingabe]);

  //TODO: dynamischer gestalten, was ist wenn mehr/weniger textblöcke vornanden sind? Jetzt fest zwei

  //Datenschutztext wird bei Zeilenumbruch (\\n) aufgeteilt. Danach wird auf zwei Seiten aufgeteilt
  let datenschutzText;
  let datenschutzParts;
  let datenschutzUeberschrift;
  let erklaerungText;
  let schlussText;

  if (seite) {
    datenschutzText = textAufbereitung(seite.htmlBlock, ausschuss);
    datenschutzParts = datenschutzText.textParts;
    datenschutzUeberschrift = datenschutzText.ueberschrift;
    //erklaerungText ersetze Platzhalter
    erklaerungText = textAufbereitung(seite.htmlBlock2, ausschuss);
    schlussText = textMitAusschussDatenErsetzen(seite.htmlBlock3);
  }
  return (
    <>
      {seite && (
        <>
          {/* Erklärungs-Grafik */}
          <div>
            {smallScreen ? (
              <div
                css={css`
                  display: flex;
                  justify-content: center;
                `}
              >
                <img
                  src={'/images/Einwilligungsgrafik-02.svg'}
                  alt={'Bei Einwilligung folgt noch ein weiterer Block'}
                  css={css`
                    max-width: 350px;
                  `}
                />
              </div>
            ) : (
              <img
                src={'/images/Einwilligungsgrafik-01.svg'}
                alt={'Bei Einwilligung folgt noch ein weiterer Block'}
              />
            )}
          </div>
          <TextBlock
            textBlockRoh={seite.htmlBlock}
            ausschuss={ausschuss}
            ueberschrift={seite.ueberschrift2}
          />
          <div
            css={css`
              display: grid;
              grid-template-columns: 1fr 1fr;
              grid-template-rows: repeat(auto-fit, auto);
              grid-gap: 30px 50px;
              grid-template-areas:
                'erklaerung-headline erklaerung-headline'
                'erklaerung1 erklaerung2'
                'zustimmung zustimmung';

              @media (max-width: 800px) {
                grid-template-columns: 1fr;
                grid-template-areas:
                  'erklaerung-headline'
                  'erklaerung1'
                  'erklaerung2'
                  'zustimmung';
              }
            `}
          >
            {/* EINWILLIGUNG */}
            <div
              css={css`
                grid-area: erklaerung-headline;
                padding-top: 30px;
              `}
            >
              <h2>{seite.ueberschrift3}</h2>
            </div>
            <div
              css={css`
                grid-area: erklaerung1;
              `}
            >
              <div
                css={css`
                  word-wrap: break-word;
                  hyphens: auto;
                `}
              >
                {erklaerungText.textParts[0].map((str, i) =>
                  str.includes('[Textfeld]') ? (
                    <Namensfeld
                      value={eingabe}
                      key={'name' + i}
                      handleChangeEingabe={handleChangeEingabe}
                    />
                  ) : (
                    <p key={'erklaerung1' + i}>{str}</p>
                  )
                )}
              </div>
            </div>
            <div
              css={css`
                grid-area: erklaerung2;
              `}
            >
              <div
                css={css`
                  word-wrap: break-word;
                  hyphens: auto;
                `}
              >
                {erklaerungText.textParts[1].map((str, i) =>
                  str.includes('[Textfeld]') ? (
                    <Namensfeld
                      value={eingabe}
                      key={'name' + i}
                      handleChangeEingabe={handleChangeEingabe}
                    />
                  ) : (
                    <p key={'erklaerung1' + i}>{str}</p>
                  )
                )}
              </div>
            </div>

            <div
              css={css`
                grid-area: zustimmung;
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: auto;
                padding-top: 20px;
                padding-bottom: 50px;
              `}
            >
              <div>
                <Checkbox
                  antwortText={seite.checkboxText}
                  frageId={'Einwilligung'}
                  checked={auswahl === '01'}
                  handleElementChange={handleChangeAuswahl}
                />
              </div>
              <div>{seite.checkboxText2}</div>
              <div
                css={css`
                  margin-top: 30px;
                `}
              >
                {schlussText}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
