/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { useEffect, useState } from 'react'; // eslint-disable-line
import { Link, useLocation } from 'react-router-dom';

import { breakpoints } from '../../styles/breakpoints';
import { fetchKonfiguration } from '../../service/fetchConfig';

const MyHeader = () => {
    const [konfiguration, setKonfiguration] = useState(null);

    useEffect(() => {
    const ladeKonfiguration = async () => {
        if(!konfiguration) {

          //Allgemeine Konfiguration (Logo etc)
          try {
            const konfigurationJSON = (await fetchKonfiguration()).data;
            setKonfiguration(konfigurationJSON);
          } catch (e) {
            console.error('Fehler beim Laden der allgemeinen Konfiguration: ' + e.message);
          }
        }
    };

    ladeKonfiguration();
  }, []);

    

  return (
    <header
      css={css`
        grid-column: 1/3;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        margin-top: 37px;
        margin-bottom: 20px;

        @media (${breakpoints.smartphone}) {
          margin-top: 16px;
          margin-bottom: 22px;
        }
      `}
      aria-label="Kopfzeile"
    >
      <div
        css={css`
          justify-self: start;
          margin-left: 38px;

          @media (${breakpoints.tablet}) {
            margin-left: 20px;
          }
          @media (${breakpoints.smartphone}) {
            margin-left: 16px;
          }
        `}
      >
        <a
          href={konfiguration ? konfiguration.logoLink : '/'}
          title={konfiguration ? konfiguration.logoTooltip : 'Zurück'}
        >
          <img
            src={
              konfiguration
                ? konfiguration.logoPfad
                : '/images/zeonline-logo.png'
            }
            alt={konfiguration ? konfiguration.logoTooltip : 'Logo'}
            className={'Logo'}
            css={css`
              height: 85px;

              @media (${breakpoints.tablet}) {
                height: 55px;
              }
              @media (${breakpoints.smartphone}) {
                height: 42px;
              }
            `}
          />
        </a>
      </div>
      <div
        css={css`
          justify-self: end;
          align-self: center;
          margin-right: 27px;

          @media (${breakpoints.smartphone}) {
            margin-right: 11px;
          }
        `}
      >
        <Link
          to={{
            pathname: '/',
            search: useLocation().search,
          }}
          title="Zurück zum Start"
        >
          <img
            src="/images/logo_oga_mit_Rand.svg"
            alt="Logo des Oberen Gutachterausschuss NRW"
            css={css`
              height: 50px;

              @media (${breakpoints.tablet}) {
                height: 36px;
                width: 174px;
              }

              @media (${breakpoints.smartphone}) {
                height: 36px;
                width: 174px;
              }
            `}
          />
        </Link>
      </div>
    </header>
  );
};
export default MyHeader;

