import React, { useState } from 'react';

import Tooltip from '../Tooltip';
import styles from './Answer.module.css';

const Checkbox = ({
  frageId,
  antwortText,
  antwortWert = '01',
  checked = false,
  grafik,
  index = 0,
  tooltipText,
  handleElementChange,
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleInputChange = (e) => {
    const target = e.target;
    setIsChecked(target.checked);
    handleElementChange(antwortWert, target.checked);
  };

  return (
    <label className={styles.container} htmlFor={index}>
      <div className={styles.container_input}>
        <input
          type="checkbox"
          checked={isChecked}
          name={antwortWert}
          id={index}
          className={styles.input_checkbox}
          aria-labelledby={'label-' + index}
          onChange={handleInputChange}
        />
        <p className={styles.label} htmlFor={index} id={'label-' + index}>
          {antwortText}
        </p>
      </div>
      <div className={styles.container_icon_tooltip_div}>
        {grafik ? (
          <img
            src={grafik}
            alt={antwortText}
            className={styles.grafik}
            aria-hidden="true"
          />
        ) : null}
        <Tooltip tooltipText={tooltipText} tooltipID={frageId + antwortWert} />
      </div>
    </label>
  );
};

export default Checkbox;
