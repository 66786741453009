/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import Loader from 'react-loader-spinner';
import { jsx, css } from '@emotion/react';

export const UploadStatus = ({ status, dateiname }) => {
  if (status.upload) {
    return (
      <div>
        {status.upload === 'success' && <span>Erfolgreich hochgeladen</span>}
        {status.upload === 'error' && (
          <span
            css={css`
              color: red;
            `}
          >
            Beim Hochladen ist ein Fehler aufgetreten, bitte versuchen Sie es
            erneut.
          </span>
        )}
        {status.upload === 'pending' && (
          <>
            <span>Wird hochgeladen... </span>
            <Loader
              css={css`
                margin-right: 10px;
              `}
              type="TailSpin"
              color="#003064"
              height={43}
              width={43}
            />
          </>
        )}
        {status.upload === 'invalidesFormat' && (
            <span
                css={css`
                  color: red;
                `}
            >
            Das gewählte Dateiformat wird nicht unterstützt.
          </span>
        )}
        {status.upload === 'virenScanPositiv' && (
            <span
                css={css`
              color: red;
            `}
            >
            Hoppla, etwas ist schief gelaufen! Der Upload Ihrer Datei wurde zurückgewiesen, bitte verwenden Sie eine andere Datei oder klicken Sie auf „Weiter“.
          </span>
        )}
        {status.upload === 'gatewayNichtErreichbar' && (
          <span
            css={css`
              color: red;
            `}
          >
            Hoppla, etwas ist schief gelaufen! Eine Datenübermittlung ist derzeit nicht möglich. Bitte versuchen Sie es später erneut.
          </span>
        )}
        {status.upload === 'bereitsHochgeladen' && (
          <>
            <span>Bereits hochgeladen: </span>
            <span>
              <em>{dateiname}</em>
            </span>
          </>
        )}
      </div>
    );
  } else if (status.delete) {
    return (
      <div>
        {status.delete === 'success' && <span>Datei erfolgreich gelöscht</span>}
        {status.delete === 'error' && (
          <span
            css={css`
              color: red;
            `}
          >
            Die Datei konnte nicht gelöscht werden, bitte versuchen Sie es
            erneut.
          </span>
        )}
        {status.delete === 'pending' && (
          <>
            <span>Wird gelöscht... </span>
            <Loader
              css={css`
                margin-right: 10px;
              `}
              type="TailSpin"
              color="#003064"
              height={43}
              width={43}
            />
          </>
        )}
      </div>
    );
  }
  return null;
};
