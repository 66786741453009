/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useState, useEffect, React } from 'react';

import Button from '../Button';

import colors from '../../styles/colors.js';
import Tooltip from '../Tooltip';
import BeschreibungMitTooltip from '../AntwortElemente/BeschreibungMitTooltip';

export default function Interaktiv({
  frageId,
  beschreibung,
  schaubild,
  returnAntwortOnChange,
  bisherigeAuswahlen,
  bisherigeEingaben = {},
  gebaeudeArt = 'Haus',
  tooltipText = '',
}) {
  const minAnzahlObergeschosse =
    schaubild.obergeschoss[0].minAnzahlObergeschosse;
  const maxAnzahlObergeschosse =
    schaubild.obergeschoss[0].maxAnzahlObergeschosse;
  const minAnzahlStaffelgeschosse =
    schaubild.staffelgeschoss[0].minAnzahlStaffelgeschosse;
  const maxAnzahlStaffelgeschosse =
    schaubild.staffelgeschoss[0].maxAnzahlStaffelgeschosse;

  //Wenn Nutzer nichts geändert hat soll auch nichts übertragen werden
  //Erst wenn eine Option getätigt wird, werden die Default-Settings übertragen.
  const [hatNutzerGeklickt, setHatNutzerGeklickt] = useState(
    Object.entries(bisherigeEingaben).length > 0 ||
      (bisherigeAuswahlen && bisherigeAuswahlen.length > 0)
  );

  /* {
    "spitzboden": {
      "auswahlen": ["06"]
    },
    "obergeschoss": {
      "auswahlen": ["05"],
      "eingaben": {
        "05": 3
      }
    }
  }*/

  const [auswahlen, setAuswahlen] = useState(
    bisherigeAuswahlen
      ? bisherigeAuswahlen
      : {
          spitzboden: schaubild.spitzboden[0].wert,
          dachgeschoss: schaubild.dachgeschoss[0].wert,
          staffelgeschoss: schaubild.staffelgeschoss[0].wert,
          obergeschoss: schaubild.obergeschoss[0].wert,
          erdgeschoss: schaubild.erdgeschoss[0].wert,
          kellergeschoss: schaubild.kellergeschoss[0].wert,
        }
  );

  const [anzahlOberGeschosse, setAnzahlOberGeschosse] = useState(
    bisherigeEingaben[schaubild.obergeschoss[0].wert]
      ? bisherigeEingaben[schaubild.obergeschoss[0].wert]
      : minAnzahlObergeschosse
  );
  const [anzahlStaffelGeschosse, setAnzahlStaffelGeschosse] = useState(
    bisherigeEingaben[schaubild.staffelgeschoss[0].wert]
      ? bisherigeEingaben[schaubild.staffelgeschoss[0].wert]
      : minAnzahlStaffelgeschosse
  );

  //Vorabladen von Bildern beim Start
  useEffect(() => {
    Object.keys(schaubild).forEach((element) => {
      schaubild[element].forEach((antwortOption) => {
        const img = new Image();
        img.src = '/images/interaktiv/' + antwortOption.grafik;
      });
    });
  }, []);

  useEffect(() => {
    let eingaben = {};
    eingaben[schaubild.obergeschoss[0].wert] = String(anzahlOberGeschosse);
    eingaben[schaubild.staffelgeschoss[0].wert] = String(
      anzahlStaffelGeschosse
    );

    if (hatNutzerGeklickt) {
      returnAntwortOnChange({
        auswahlen: auswahlen,
        eingaben: eingaben,
      });
    }
  }, [auswahlen, anzahlOberGeschosse, anzahlStaffelGeschosse]);

  //Klick-Handler für Hinzufügen/Löschen von Geschossen
  //wird wie eine Nummer-Eingabe behandelt
  const handleAuswahlGeschosse = (e, type) => {
    e.preventDefault();

    setHatNutzerGeklickt(true);

    if (type === 'obergeschoss') {
      setAnzahlOberGeschosse(e.target.value);
    } else {
      setAnzahlStaffelGeschosse(e.target.value);
    }
  };

  //Klick-Handler für Ändern
  const handleClickChange = (e, element) => {
    e.preventDefault();

    setHatNutzerGeklickt(true);

    const antwortOptionen = schaubild[element];
    const aktuellerWert = e.target.value;

    //Index der aktuell gewählten Option
    const aktuellerIndex = antwortOptionen.findIndex(
      (option) => option.wert === aktuellerWert
    );
    //Antworten aktualisieren
    setAuswahlen({
      ...auswahlen,
      [element]: antwortOptionen[aktuellerIndex].wert,
    });
  };

  //Helper functions
  const getAktuellenElementWert = (element) => {
    if (auswahlen[element]) {
      return auswahlen[element];
    } else {
      return schaubild[element][0].wert; //immer das erste Element als default
    }
  };

  const getAntwortOptionByWert = (element, wert) => {
    return schaubild[element].find((option) => option.wert === wert);
  };

  const generiereListeVonGeschossen = (typ) => {
    const listeVonGeschossen = [];
    if (typ === 'staffelgeschoss') {
      for (
        let i = minAnzahlStaffelgeschosse;
        i <= maxAnzahlStaffelgeschosse;
        i += 1
      ) {
        listeVonGeschossen.push({ wert: i, text: i });
      }
    } else {
      for (
        let i = minAnzahlObergeschosse;
        i <= maxAnzahlObergeschosse;
        i += 1
      ) {
        listeVonGeschossen.push({ wert: i, text: i });
      }
    }
    return listeVonGeschossen;
  };

  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
      `}
    >
      <BeschreibungMitTooltip
        beschreibung={beschreibung}
        tooltipText={tooltipText}
        tooltipID={frageId}
        gebaeudeArt={gebaeudeArt}
      />
      <SchaubildBox>
        <SchaubildGrafik
          getAktuellenElementWert={getAktuellenElementWert}
          getAntwortOptionByWert={getAntwortOptionByWert}
          element="spitzboden"
        />
        <SchaubildAuswahl
          antwortOptionen={schaubild['spitzboden']}
          text="Spitzboden Ausbauzustand ändern"
          selectedElement={getAktuellenElementWert('spitzboden')}
          handleClick={(e) => handleClickChange(e, 'spitzboden')}
        />
      </SchaubildBox>
      <SchaubildBox>
        <SchaubildGrafik
          getAktuellenElementWert={getAktuellenElementWert}
          getAntwortOptionByWert={getAntwortOptionByWert}
          element="dachgeschoss"
        />
        <SchaubildAuswahl
          antwortOptionen={schaubild['dachgeschoss']}
          text="Dachgeschoss ändern"
          selectedElement={getAktuellenElementWert('dachgeschoss')}
          handleClick={(e) => handleClickChange(e, 'dachgeschoss')}
        />
      </SchaubildBox>
      <SchaubildBox>
        <div
          css={css`
            display: grid;
            align-items: center;
          `}
        >
          {Array.from({ length: anzahlStaffelGeschosse }, (item, index) => (
            <SchaubildGrafik
              getAktuellenElementWert={getAktuellenElementWert}
              getAntwortOptionByWert={getAntwortOptionByWert}
              element="staffelgeschoss"
              key={'sg' + index}
            />
          ))}
        </div>
        <div>
          <SchaubildAuswahl
            antwortOptionen={generiereListeVonGeschossen('staffelgeschoss')}
            text="Anzahl der Staffelgeschosse wählen"
            selectedElement={anzahlStaffelGeschosse}
            handleClick={(e) => handleAuswahlGeschosse(e, 'staffelgeschoss')}
          />
        </div>
      </SchaubildBox>
      <SchaubildBox>
        <div
          css={css`
            display: grid;
            align-items: center;
          `}
        >
          {Array.from({ length: anzahlOberGeschosse }, (item, index) => (
            <SchaubildGrafik
              getAktuellenElementWert={getAktuellenElementWert}
              getAntwortOptionByWert={getAntwortOptionByWert}
              element="obergeschoss"
              key={'og' + index}
            />
          ))}
        </div>
        <SchaubildAuswahl
          antwortOptionen={generiereListeVonGeschossen('staffelgeschoss')}
          text="Anzahl der Obergeschosse wählen"
          selectedElement={anzahlOberGeschosse}
          handleClick={(e) => handleAuswahlGeschosse(e, 'obergeschoss')}
        />
      </SchaubildBox>

      <SchaubildBox>
        <SchaubildGrafik
          getAktuellenElementWert={getAktuellenElementWert}
          getAntwortOptionByWert={getAntwortOptionByWert}
          element="erdgeschoss"
        />
      </SchaubildBox>
      <SchaubildBox>
        <SchaubildGrafik
          getAktuellenElementWert={getAktuellenElementWert}
          getAntwortOptionByWert={getAntwortOptionByWert}
          element="kellergeschoss"
        />
        <SchaubildAuswahl
          antwortOptionen={schaubild['kellergeschoss']}
          selectedElement={getAktuellenElementWert('kellergeschoss')}
          text="Kellergeschoss ändern"
          handleClick={(e) => handleClickChange(e, 'kellergeschoss')}
        />
      </SchaubildBox>
    </div>
  );
}

//Zusätzliche Komponenten
const SchaubildAuswahl = ({
  antwortOptionen,
  text,
  handleClick = () => {},
  selectedElement,
}) => (
  <label>
    {text}
    <select
      css={css`
        background-color: ${colors.lightlightgray};
        border-radius: 2px;
        border: 1px solid #000000;
        font-size: 14px;
        color: ${colors.blue};
        text-align: center;
        height: 60px;
        width: 100%;
        padding: 12px 25px;
        cursor: pointer;
        margin-bottom: 5px;
        &:focus {
          outline: 4px solid #005bbd;
        }

        @media (max-width: 800px) {
          font-size: 14px;
        }

        @media (max-width: 360px) {
          font-size: 12px;
        }
      `}
      onChange={(e) => handleClick(e)}
    >
      {antwortOptionen?.map((element, i) =>
        String(element.wert) === selectedElement ? (
          <option key={i} value={element.wert} selected="selected">
            {element.text}
          </option>
        ) : (
          <option key={i} value={element.wert}>
            {element.text}
          </option>
        )
      )}
    </select>
  </label>
);

const SchaubildCSSGrafik = ({ text }) => {
  return (
    <div
      css={css`
        width: 170px;
        height: 70px;
        border: 3px solid ${colors.blue};
        justify-self: center;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 800px) {
          width: 80%;
        }

        @media (max-width: 360px) {
          border: 2px solid ${colors.blue};
          height: 40px;
          width: 120px;
        }
      `}
    >
      <div
        css={css`
          flex: 0 0 120px;
          font-weight: bold;

          @media (max-width: 800px) {
            flex: 0 0 60px;
          }

          @media (max-width: 360px) {
            font-size: 12px;
          }
        `}
      >
        {text}
      </div>
    </div>
  );
};

//Stellt Hauselement in dem aktuellen Zustand dar, übergeben wird nur der Name des Elementes (z.B. 'spitzboden')
const SchaubildGrafik = ({
  element,
  getAktuellenElementWert,
  getAntwortOptionByWert,
}) => {
  const aktuellerAntwortWert = getAktuellenElementWert(element);
  const antwortOption = getAntwortOptionByWert(element, aktuellerAntwortWert);

  return (
    <div
      css={css`
        width: 200px;
        height: 100%;

        justify-self: center;

        @media (max-width: 800px) {
          width: 80%;
        }

        @media (max-width: 360px) {
          width: 120px;
        }
      `}
      aria-hidden="true"
    >
      <img
        src={'/images/interaktiv/' + antwortOption.grafik}
        alt={antwortOption.text}
        css={css`
          width: 100%;
          height: 100%;
          margin-bottom: 15px;

          @media (max-width: 360px) {
            min-height: 60px;
          }
        `}
      />
    </div>
  );
};

const SchaubildBox = (props) => {
  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: 0.3fr 1fr 1fr 0.6fr;
        align-items: center;

        @media (max-width: 1600px) {
          grid-template-columns: 0.3fr 1fr 1fr 0.3fr;
        }

        @media (max-width: 1250px) {
          grid-template-columns: 0.1fr 1fr 1fr 0.1fr;
        }

        @media (max-width: 800px) {
          grid-template-columns: 0fr 1fr 1fr 0fr;
        }
      `}
    >
      <div></div>
      {props.children}
      <div></div>
    </div>
  );
};
