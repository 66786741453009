/** @jsxRuntime classic */
/** @jsx jsx */
import styles from './Answer.module.css';
import uniqueId from 'lodash.uniqueid';

import { jsx, css } from '@emotion/react';

import Tooltip from '../Tooltip';

import { useState, React } from 'react';

const Radio = ({
  antwortText,
  antwortWert,
  frageId,
  checked = false,
  grafik,
  tooltipText,
  handleElementChange,
}) => {
  const [id] = useState(() => uniqueId('singleChoiceAnswer-'));

  const handleInputChange = () => {
    handleElementChange(antwortWert);
  };

  return (
    <div className={[styles.radio_container]}>
      <label className={[styles.label]}>
        <input
          type="radio"
          value={antwortWert}
          name={frageId}
          checked={checked}
          aria-label={tooltipText}
          className={styles.input}
          onChange={(e) => handleInputChange(e)}
        />
        {antwortText}
      </label>
      <div className={styles.container_icon_tooltip_div} aria-hidden="true">
        {grafik ? (
          <img src={grafik} alt={antwortText} className={styles.grafik} />
        ) : null}
        <Tooltip tooltipText={tooltipText} tooltipID={'Tooltip' + id} />
      </div>
    </div>
  );
};

export default Radio;
