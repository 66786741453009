/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React from 'react'; // eslint-disable-line

import colors from '../../styles/colors.js';
import { breakpoints } from '../../styles/breakpoints';

const LinkeSeite = (props) => (
  <div
    css={css`
      background-color: ${colors.blue};
      color: white;
      display: grid;
      /* [blauer space links] [content] [blauer space rechts]*/
      grid-template-columns: 80px auto 40px;
      /* [blauer space oben] [content] [blauer space unten]*/
      grid-template-rows: 100px auto 40px;

      @media (${breakpoints.tablet}) {
        grid-template-columns: 60px auto 50px;
        grid-template-rows: 50px auto 40px;
      }

      @media (${breakpoints.smartphone}) {
        grid-template-columns: 20px auto 20px;
        grid-template-rows: 10px auto 10px;
      }
    `}
  >
    <div
      css={css`
        grid-row: 1/2;
      `}
    ></div>
    <div
      css={css`
        grid-column: 2/3;
        grid-row: 2/3;
      `}
    >
      {props.children}
    </div>
    <div
      css={css`
        grid-row: 3/3;
      `}
    ></div>
  </div>
);

export default LinkeSeite;
