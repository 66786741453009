/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

export const textMitAusschussDatenErsetzen = (text, ausschuss) => {
  if (!ausschuss) {
    ausschuss = {
      vollerName: 'Gutachterauschuss',
      gaaEmail: 'Gutachterauschuss-Email',
    };
  }
  if (!text) {
    return text;
  }
  return text
    .replaceAll('[Gutachterauschuss]', ausschuss.vollerName)
    .replaceAll('[Gutachterauschuss-Email]', ausschuss.gaaEmail);
};

export function textAufbereitung(textBlock, ausschuss) {
  if (!textBlock) {
    return textBlock;
  }

  const bearbeiteterText = textMitAusschussDatenErsetzen(textBlock, ausschuss);
  //Der Text wird bei Zeilenumbruch (<br/>) aufgeteilt. Danach wird er auf zwei Seiten aufgeteilt

  return {
    ueberschrift: textBlock.ueberschrift,
    textParts: teileArray(bearbeiteterText.split('<br/>')),
  };
}

//Array in zwei Teile aufteilen
const teileArray = (array) => {
  const half = Math.ceil(array.length / 2);
  const firstHalf = array.splice(0, half);
  const secondHalf = array.splice(-half);

  return [firstHalf, secondHalf];
};

export default function TextBlock({ textBlockRoh, ausschuss, ueberschrift }) {
  const textBlock = textAufbereitung(textBlockRoh, ausschuss);
  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px 50px;
        grid-template-areas:
          'headline headline'
          'text1 text2';
        @media (max-width: 800px) {
          grid-template-columns: 1fr;
          grid-template-areas:
            'headline '
            'text1'
            'text2';
        }
      `}
    >
      <div
        css={css`
          grid-area: headline;
          padding-top: 10px;
        `}
      >
        <h2>{ueberschrift}</h2>
      </div>
      <div
        css={css`
          grid-area: text1;
        `}
      >
        <div
          css={css`
            word-wrap: break-word;
            hyphens: auto;
          `}
        >
          {textBlock.textParts[0].map((str, i) => (
            <p key={'text1' + i}>{str}</p>
          ))}
        </div>
      </div>
      <div
        css={css`
          grid-area: text2;
        `}
      >
        <div
          css={css`
            word-wrap: break-word;
            hyphens: auto;
          `}
        >
          {textBlock.textParts[1].map((str, i) => (
            <p key={'text2' + i}>{str}</p>
          ))}
        </div>
      </div>
    </div>
  );
}
