/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

import Radio from '../AntwortElemente/Radio';

import React, { useState, useEffect } from 'react';
import AuswahlReset from './AuswahlReset';
import Textbereich from '../AntwortElemente/Textbereich';
import BeschreibungMitTooltip from '../AntwortElemente/BeschreibungMitTooltip';

export default function Einfachauswahl({
  antwortOptionen,
  frageId,
  beschreibung,
  returnAntwortOnChange,
  bisherigeAuswahlen,
  bisherigeEingaben = {},
  bilderPfad,
  tooltipText,
  gebaeudeArt,
}) {
  const [auswahl, setAuswahl] = useState(
    bisherigeAuswahlen ? bisherigeAuswahlen[0] : null
  );
  const [eingaben, setEingaben] = useState(bisherigeEingaben);

  const getIsChecked = (antwortOptionWert) => {
    return (
      auswahl === antwortOptionWert && auswahl?.includes(antwortOptionWert)
    );
  };
  const [antworten, setAntworten] = useState(antwortOptionen);

  // setze alle Antworten auf inaktiv, außer die gewählte
  const setzeAntworten = (antwortWert) => {
    const neueAntworten = antworten.map((antwort) => ({
      ...antwort,
      aktiv: antwort.wert === antwortWert,
    }));
    setAntworten(neueAntworten);
  };

  const handleChangeAuswahl = (antwortWert) => {
    setzeAntworten(antwortWert);
    setAuswahl(antwortWert);
    setEingaben({});
  };

  useEffect(() => {
    setzeAntworten(auswahl);
    if (auswahl) {
      const leer = Object.keys(eingaben).length !== 0;
      if (eingaben && leer) {
        returnAntwortOnChange({ auswahlen: [auswahl], eingaben: eingaben });
      } else {
        returnAntwortOnChange({ auswahlen: [auswahl] });
      }
    } else {
      returnAntwortOnChange({ auswahlen: [] });
    }
  }, [auswahl, eingaben]);

  const setzeRatingZurueck = () => {
    setAuswahl(null);
    setEingaben({});
    setAntworten(antworten.map((antwort) => ({ ...antwort, aktiv: false })));
  };

  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(auto-fit, auto);
        grid-gap: 0px 38px;
      `}
    >
      <BeschreibungMitTooltip
        beschreibung={beschreibung}
        tooltipText={tooltipText}
        tooltipID={frageId}
        gebaeudeArt={gebaeudeArt}
      />
      <div>
        {antworten
          ? antworten.map((antwortOption, index) => (
              <div key={index}>
                <Radio
                  antwortText={antwortOption.text}
                  frageId={frageId}
                  handleElementChange={handleChangeAuswahl}
                  antwortWert={antwortOption.wert}
                  checked={antwortOption.aktiv}
                  tooltipText={antwortOption.tooltip}
                  grafik={
                    antwortOption.grafik
                      ? `${bilderPfad}${antwortOption.grafik}`
                      : null
                  }
                  key={frageId + antwortOption.wert}
                />
                {antwortOption.eingabefeld === 'ja' &&
                getIsChecked(antwortOption.wert) ? (
                  <Textbereich
                    antwortWert={antwortOption.wert}
                    value={eingaben[antwortOption.wert]}
                    index={index}
                    label={antwortOption.text}
                    handleElementChange={(eingabe) => {
                      const eingabenCopy = Object.assign({}, eingaben);
                      eingabenCopy[antwortOption.wert] = eingabe;
                      setEingaben(eingabenCopy);
                    }}
                    rows={3}
                    background={'#f4f4f4'}
                  />
                ) : null}
              </div>
            ))
          : null}
      </div>

      <AuswahlReset zurueckSetzen={setzeRatingZurueck} />
    </div>
  );
}
