/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import colors from '../../styles/colors.js';
import { breakpoints } from '../../styles/breakpoints';
import styles from './Footer.module.css';
import { useLocation } from 'react-router-dom';

import React from 'react'; // eslint-disable-line

const iconPath = '/images/open-in-new-tab.svg';
const altText = 'in neuen tab öffnen';
const Footer = () => {
  const query = useLocation().search;
  const params = new URLSearchParams(query);

  const ausschussNummer = params.get('ausschuss');
  //const ausschussNummer = localStorage.getItem('ausschuss');
  return (
    <footer
      css={css`
        background-color: ${colors.blue};
        border-top: 1px solid ${colors.darkgray};
        grid-column: 1/2;
        color: white;
        height: 100%;
        ul {
          list-style-type: none;
        }

        li {
          display: inline-flex;
          margin-right: 20px;
          margin-top: 8px;
        }

        a {
          color: white;
          font-size: 14px;
        }
      `}
      aria-label="Fußzeile"
    >
      <div
        css={css`
          margin-bottom: 13px;
          margin-left: 38px;
          margin-top: 0px;

          @media (${breakpoints.tablet}) {
            margin-left: 20px;
          }
          @media (${breakpoints.smartphone}) {
            margin-left: 10px;
          }
        `}
      >
        <ul>
          {' '}
          {/* Jeder Link hier muss in einen neuen Tab geöffnet werden (Anforderung) */}
          <li>
            <a
              href={
                ausschussNummer
                  ? `/ansprechpartner?ausschuss=${ausschussNummer}`
                  : '/ansprechpartner'
              }
              title="Hier geht es weiter zu den Ansprechpartner"
              target="_blank"
            >
              Ansprechpartner
              <img src={iconPath} alt={altText} className={styles.icon} />
            </a>
          </li>
          {/*<li>
          <a
            href="/feedback"
            title="Hier geht es weiter zum Feedback Formular"
            target="_blank"
          >
            Feedback
            <img src={iconPath} alt={altText} className={styles.icon} />
          </a>
        </li>*/}
          <li>
            <a
              href={
                ausschussNummer
                  ? `/datenschutz?ausschuss=${ausschussNummer}`
                  : '/datenschutz'
              }
              title="Hier finden Sie unsere Datenschutzrichtlinien"
              target="_blank"
            >
              Datenschutz
              <img src={iconPath} alt={altText} className={styles.icon} />
            </a>
          </li>
          <li>
            <a
              href={
                ausschussNummer
                  ? `/impressum?ausschuss=${ausschussNummer}`
                  : '/impressum'
              }
              title="Hier finden Sie das Impressum"
              target="_blank"
            >
              Impressum
              <img src={iconPath} alt={altText} className={styles.icon} />
            </a>
          </li>
          <li
            css={css`
              float: right;
              font-size: 12px;
            `}
          >
            Mit ❤ aus Paderborn
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
