/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/react';
import { breakpoints } from '../styles/breakpoints';

import ReactTooltip from 'react-tooltip';
import colors from '../styles/colors.js';

import { setGenitiv } from '../service/frageUtils';

const Tooltip = ({ tooltipText = '', tooltipID }) => {
  const id = 'Tooltip-ID:' + tooltipID;
  return tooltipText ? (
    <>
      <img
        src={'/images/info_black.svg'}
        alt={tooltipText != '' ? tooltipText : 'Tooltip'}
        css={css`
          width: 20px;
          height: 20px;
          margin: 5px;
        `}
        data-tip={tooltipText}
        data-for={id}
      />
      <ReactTooltip
        id={id}
        place="top"
        type="dark"
        effect="float"
        multiline={true}
        css={css`
          max-width: 30vw;
          text-align: left !important;
          @media (${breakpoints.tablet}) {
            max-width: 200px;
          }
        `}
      />
    </>
  ) : null;
};
export default Tooltip;
