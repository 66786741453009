/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

import React, { useState, useEffect } from 'react'; // eslint-disable-line

import LowerSide from '../components/Layout/LowerSide';
import TwoRowsBox from '../components/Layout/TwoRowsBox';
import { fetchAusschuesse, fetchSeitenDaten } from '../service/fetchConfig';
import { ENDPOINTS } from '../service/api';
import { useLocation } from 'react-router-dom';
import EmailLink from '../components/EmailLink';

export default function Ansprechpartner() {
  const [ausschuss, setAusschuss] = useState();
  const query = useLocation().search;
  const params = new URLSearchParams(query);
  const ausschussNummer = params.get('ausschuss');
  useEffect(() => {
    const ladeAusschuss = async () => {
      if (ausschussNummer) {
        const ausschussData = (await fetchAusschuesse(ausschussNummer)).data;
        setAusschuss(ausschussData);
      }
    };

    ladeAusschuss();
  }, [ausschussNummer]);

  //lade oberen gutachterausschuss
  const [ogAusschuss, setOGAusschuss] = useState();
  useEffect(() => {
    const ladeAusschuss = async () => {
      const ausschussData = (await fetchAusschuesse(0)).data;

      setOGAusschuss(ausschussData);
    };

    ladeAusschuss();
  }, []);

  const [seitenDaten, setSeitenDaten] = useState();
  useEffect(() => {
    const ladeSeitenDaten = async () => {
      const seitenDaten = (await fetchSeitenDaten(ENDPOINTS.ANSPRECHPARTNER))
        .data;
      setSeitenDaten(seitenDaten);
    };

    ladeSeitenDaten();
  }, []);

  return (
    <TwoRowsBox>
      <LowerSide>
        <div>
          <h1>Ansprechpartner</h1>
          {seitenDaten && (
            <div
              dangerouslySetInnerHTML={{
                __html: seitenDaten?.text1,
              }}
            />
          )}
          <div
            css={css`
              display: grid;
              grid-template-columns: 1fr 1fr;
              grid-gap: 30px 0px;
              grid-template-areas: 'text1 text2';
              @media (max-width: 800px) {
                grid-template-columns: 1fr;
                grid-template-areas:
                  'text1'
                  'text2';
              }
            `}
          >
            {ausschuss && (
              <div
                css={css`
                  grid-area: text1;
                  padding-top: 40px;
                  padding-right: 40px;
                `}
              >
                <h2>{ausschuss && 'Der ' + ausschuss.vollerName} </h2>
                <p>Tel: {ausschuss && ausschuss.gaaTelefon}</p>
                <p>
                  E-Mail: <EmailLink email={ausschuss && ausschuss.gaaEmail} />
                </p>
              </div>
            )}
            <div
              css={css`
                grid-area: text2;
                padding-top: 40px;
              `}
            >
              <h2>{ogAusschuss && ogAusschuss.vollerName}</h2>
              <p>Tel: {ogAusschuss && ogAusschuss.gaaTelefon}</p>
              <p>
                E-Mail:
                <EmailLink email={ogAusschuss && ogAusschuss.gaaEmail} />
              </p>
            </div>
          </div>
        </div>
      </LowerSide>
    </TwoRowsBox>
  );
}
