import React from 'react';
import { useLocation } from 'react-router-dom';
import { EinzelUpload } from './EinzelUpload';

const MultiUpload = ({
  ausgewaehlteDateien,
  erlaubeAlleFormate,
  frageId,
  id,
  maximalErlaubteDateien,
  returnAntwortOnChange,
  text,
}) => {
  const [frageMetadaten, setFrageMetadaten] = React.useState(null);
  const [hochgeladeneDateien, setHochgeladeneDateien] = React.useState(
    ausgewaehlteDateien
  );
  const query = useLocation().search;

  const gegebeneAntworten = hochgeladeneDateien[id] || [];

  React.useEffect(() => {
    const params = new URLSearchParams(query);
    const ausschuss = params.get('ausschuss');
    const gemeinde = params.get('gemeinde');
    const regNummer = params.get('regnummer');
    const code = params.get('code');

    setFrageMetadaten({
      ausschussNr: ausschuss,
      gemeindeNr: gemeinde,
      regNr: regNummer,
      pruefCode: code,
      frageId,
    });

    returnAntwortOnChange({ dateien: hochgeladeneDateien });
  }, []);

  const addFile = (id, dateiname, originalDateiname, mimeType, uuid) => {
    const hochgeladeneDateienFuerId = hochgeladeneDateien[id];
    const datei = {
      dateiname,
      originalDateiname,
      typ: mimeType,
      uuid,
    };
    const neueDateien = {
      ...hochgeladeneDateien,
      [id]: Array.isArray(hochgeladeneDateienFuerId)
        ? [...hochgeladeneDateienFuerId, datei]
        : [datei],
    };
    //Ergebnis wird sofort gespeichert
    returnAntwortOnChange(
      {
        dateien: neueDateien,
      },
      true
    );
    setHochgeladeneDateien(neueDateien);
  };

  const deleteFile = (id, zuLoeschendeUuid) => {
    const neueDateien = { ...hochgeladeneDateien };
    neueDateien[id] = neueDateien[id].filter(
      ({ uuid }) => uuid !== zuLoeschendeUuid
    );
    //Ergebnis wird sofort gespeichert
    returnAntwortOnChange(
      {
        dateien: neueDateien,
      },
      true
    );
    setHochgeladeneDateien(neueDateien);
  };

  return (
    <div>
      {gegebeneAntworten.map(
        (hochgeladeneDatei, index) => (
          <EinzelUpload
            key={'upload-file-' + index + frageId}
            bereitsAusgewaehlt={true}
            hochgeladeneDatei={hochgeladeneDatei}
            erlaubeAlleFormate={erlaubeAlleFormate}
            frageId={frageId}
            frageMetadaten={frageMetadaten}
            id={id}
            isLastElement={false}
            onAdd={addFile}
            onDelete={deleteFile}
            text={text}
          />
        )
      )}
      {maximalErlaubteDateien > gegebeneAntworten.length ? (
        <EinzelUpload
          key={'upload-file-' + (gegebeneAntworten.length + 1) + frageId}
          bereitsAusgewaehlt={false}
          hochgeladeneDatei={null}
          erlaubeAlleFormate={erlaubeAlleFormate}
          frageMetadaten={frageMetadaten}
          id={id}
          isLastElement={true}
          frageId={frageId}
          onAdd={addFile}
          onDelete={deleteFile}
          text={text}
        />
      ) : (
        <p>
          Sie haben die maximal erlaubte Anzahl hochgeladener Dateien (
          {maximalErlaubteDateien}) für diese Frage erreicht.
        </p>
      )}
    </div>
  );
};

export default MultiUpload;
