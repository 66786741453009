export const API_HOST = process.env.REACT_APP_API_HOST;
export const ENDPOINTS = {
  ERGEBNISSE: '/api/client/v1/ergebnisse',
  FRAGEN12: '/api/client/v1/fragenblock12',
  FRAGEN3: '/api/client/v1/fragenblock3',
  AUSSCHUESSE: '/api/client/v1/ausschuesse',
  AUSSCHUESSEDETAILS: '/api/client/v1/ausschuessedetails',
  GEMEINDEN: '/api/client/v1/gemeinden',
  KONFIGURATION: '/api/client/v1/einstellungen',
  FEEDBACK: '/api/client/v1/feedback',
  SEITENDATEN: '/api/client/v1/content',
  IMPRESSUM: 'impressum',
  ANSPRECHPARTNER: 'ansprechpartner',
  DATENSCHUTZ: 'datenschutz',
  EINWILLIGUNG: 'einwilligung',
  NICHTGEFUNDENSEITE: 'nichtGefundenSeite',
  STARTSEITE: 'startseite',
};
