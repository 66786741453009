/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useState, React } from 'react';
import { breakpoints } from '../../styles/breakpoints';

import Rating from '@material-ui/lab/Rating';
import { makeStyles } from '@material-ui/core/styles';
import BeschreibungMitTooltip from '../AntwortElemente/BeschreibungMitTooltip';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import AuswahlReset from './AuswahlReset';

const imgPath = '/images/star.svg';
const altText = 'Bild von einem Stern';

export default function Schieberegler({
  returnAntwortOnChange,
  bisherigeEingaben,
  min,
  max,
  schritt,
  labels,
  beschreibung,
  frageId,
  tooltipText,
  gebaeudeArt,
}) {
  const [sterneRating, setSterneRating] = useState(
    bisherigeEingaben ? bisherigeEingaben['01'] : null
  );

  const [sliderValue, setSliderValue] = useState(
    bisherigeEingaben ? bisherigeEingaben['01'] : min
  );

  const setzeRatingZurueck = () => {
    setSterneRating(null);
    setSliderValue(min);
    returnAntwortOnChange({
      eingaben: {},
    });
  };

  const handleChangeSlider = (sliderWert) => {
    setSterneRating(sliderWert);
    setSliderValue(sliderWert);

    returnAntwortOnChange({
      eingaben: {
        //da wir immer nur 1 Eingabefeld haben ist 01 fest
        '01': sliderWert,
      },
    });
  };

  const handleChangeRating = (ratingWert) => {
    setSterneRating(ratingWert);
    setSliderValue(ratingWert);

    returnAntwortOnChange({
      eingaben: {
        //da wir immer nur 1 Eingabefeld haben ist 01 fest
        '01': ratingWert,
      },
    });
  };

  const imgStar = css({
    width: '24px',
  });

  const useStyles = makeStyles({
    root: {
      fontSize: '2.3em',
    },
    iconFilled: {
      color: '#003064',
    },
    iconHover: {
      color: '#003064',
    },
  });
  const StyledRating = ({
    name,
    size,
    defaultValue,
    value,
    min,
    max,
    precision,
    onChange,
  }) => {
    return (
      <Rating
        name={name}
        defaultValue={defaultValue}
        value={value}
        min={min}
        max={max}
        precision={precision}
        onChange={onChange}
        classes={useStyles()}
        // Das Rating muss ReadOnly sein,
        // da man sonst mit Tab das Element foccussieren kann und da dann die Tabeingabe nicht ordnungsgemäß funktioniert.
        // Nach jedem Eingabeschritt wird zum Seitenstart zurückgesprungen.
        readOnly={true}
        getLabelText={(value) => {
          return `${value} Stern${value !== 1 ? 'e' : ''}`;
        }}
        emptyLabelText="Keine Antwort"
      />
    );
  };

  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(auto-fit, auto);
        grid-gap: 0px 38px;
      `}
    >
      <BeschreibungMitTooltip
        beschreibung={beschreibung}
        tooltipText={tooltipText}
        tooltipID={frageId}
        gebaeudeArt={gebaeudeArt}
      />
      <div
        css={css`
          width: 60%;
          margin-left: 20%;
          margin-top: 50px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          @media (${breakpoints.smartphone}) {
            width: 80%;
            margin-left: 10%;
          }
        `}
      >
        <div className="screenreader-only">
          <p>
            Ein Stern bedeutet {labels[min - 1]}. Fünf Sterne bedeuten{' '}
            {labels[max - 1]}.
          </p>
        </div>
        <StyledRating
          name="half-rating"
          size="large"
          defaultValue={min}
          value={sterneRating}
          min={min}
          max={max}
          precision={schritt}
          onChange={(event, rating) => {
            handleChangeRating(rating);
          }}
        />
        <h2>
          {sterneRating === null
            ? 'Keine Antwort'
            : labels[Math.round(sterneRating) - min]}
        </h2>
        <Slider
          min={min}
          max={max}
          step={schritt}
          onChange={handleChangeSlider}
          defaultValue={bisherigeEingaben ? bisherigeEingaben['01'] : min}
          value={sliderValue}
          css={css`
            margin-top: 8%;
            .rc-slider-handle:focus {
              outline: solid;
            }
          `}
        />
        <div
          aria-hidden="true"
          css={css`
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
          `}
        >
          <div>
            <img src={imgPath} alt={altText} css={imgStar} />
            <p
              css={css`
                max-width: 30%;
                text-align: left;
                color: #003064;
              `}
            >
              {labels[min - 1]}
            </p>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-end;
            `}
          >
            <div
              css={css`
                display: flex;
              `}
            >
              <img src={imgPath} alt={altText} css={imgStar} />
              <img src={imgPath} alt={altText} css={imgStar} />
              <img src={imgPath} alt={altText} css={imgStar} />
              <img src={imgPath} alt={altText} css={imgStar} />
              <img src={imgPath} alt={altText} css={imgStar} />
            </div>
            <p
              css={css`
                max-width: 50%;
                text-align: right;
                color: #003064;
              `}
            >
              {labels[max - 1]}
            </p>
          </div>
        </div>
      </div>
      <AuswahlReset zurueckSetzen={setzeRatingZurueck} />
    </div>
  );
}
